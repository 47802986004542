import React, {memo} from "react";

export const IconBookmark = memo(({color, width, height, addClass, ...props}) => {
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill="none"
      className={addClass}
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 19.5C15.857 19.5 15.717 19.438 15.62 19.325L10 12.768L4.38 19.325C4.244 19.484 4.023 19.541 3.827 19.469C3.631 19.397 3.5 19.209 3.5 19V1C3.5 0.724 3.724 0.5 4 0.5H16C16.276 0.5 16.5 0.724 16.5 1V19C16.5 19.209 16.37 19.396 16.173 19.469C16.116 19.49 16.058 19.5 16 19.5ZM10 11.5C10.146 11.5 10.285 11.564 10.38 11.675L15.5 17.649V1.501H4.5V17.649L9.62 11.675C9.715 11.564 9.854 11.5 10 11.5Z"
        fill={color || "#000"}
      />
      <rect
        x="12.5"
        y="6"
        width="1"
        height="5"
        rx="0.5"
        transform="rotate(90 12.5 6)"
        fill={color || "#000"}
      />
      <rect x="9.5" y="4" width="1" height="5" rx="0.5" fill={color || "#000"} />
    </svg>
  );
});
