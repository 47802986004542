import React, {memo} from "react";

export const IconBandaid = memo(({color, addClass}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={addClass}
    >
      <path
        d="M18.103 1.896C17.225 1.018 16.014 0.533997 14.694 0.533997C13.146 0.533997 11.594 1.199 10.434 2.359L2.359 10.434C1.286 11.507 0.641995 12.891 0.545995 14.331C0.448995 15.796 0.927995 17.136 1.896 18.104C2.774 18.982 3.985 19.466 5.306 19.466C6.854 19.466 8.406 18.801 9.566 17.641L17.641 9.566C18.714 8.493 19.358 7.109 19.454 5.669C19.551 4.204 19.072 2.864 18.104 1.897L18.103 1.896ZM9.99899 4.207L15.792 10L9.99899 15.793L4.206 10L9.99899 4.207ZM5.305 18.466C4.252 18.466 3.292 18.086 2.603 17.397C1.006 15.8 1.213 12.994 3.065 11.142L3.499 10.708L9.292 16.501L8.858 16.935C7.88499 17.908 6.59 18.467 5.305 18.467V18.466ZM16.933 8.859L16.499 9.293L10.706 3.5L11.14 3.066C12.113 2.093 13.408 1.534 14.693 1.534C15.746 1.534 16.706 1.914 17.395 2.603C18.992 4.2 18.785 7.006 16.933 8.858V8.859Z"
        fill={color || "#000"}
      />
      <path
        d="M10.499 6C10.499 6.276 10.275 6.5 9.99899 6.5C9.72299 6.5 9.49899 6.276 9.49899 6C9.49899 5.724 9.72299 5.5 9.99899 5.5C10.275 5.5 10.499 5.724 10.499 6Z"
        fill={color || "#000"}
      />
      <path
        d="M8.49899 8C8.49899 8.276 8.27499 8.5 7.99899 8.5C7.72299 8.5 7.49899 8.276 7.49899 8C7.49899 7.724 7.72299 7.5 7.99899 7.5C8.27499 7.5 8.49899 7.724 8.49899 8Z"
        fill={color || "#000"}
      />
      <path
        d="M6.49899 10C6.49899 10.276 6.27499 10.5 5.99899 10.5C5.72299 10.5 5.49899 10.276 5.49899 10C5.49899 9.724 5.72299 9.5 5.99899 9.5C6.27499 9.5 6.49899 9.724 6.49899 10Z"
        fill={color || "#000"}
      />
      <path
        d="M10.499 8C10.499 8.276 10.275 8.5 9.99899 8.5C9.72299 8.5 9.49899 8.276 9.49899 8C9.49899 7.724 9.72299 7.5 9.99899 7.5C10.275 7.5 10.499 7.724 10.499 8Z"
        fill={color || "#000"}
      />
      <path
        d="M12.499 8C12.499 8.276 12.275 8.5 11.999 8.5C11.723 8.5 11.499 8.276 11.499 8C11.499 7.724 11.723 7.5 11.999 7.5C12.275 7.5 12.499 7.724 12.499 8Z"
        fill={color || "#000"}
      />
      <path
        d="M8.49899 10C8.49899 10.276 8.27499 10.5 7.99899 10.5C7.72299 10.5 7.49899 10.276 7.49899 10C7.49899 9.724 7.72299 9.5 7.99899 9.5C8.27499 9.5 8.49899 9.724 8.49899 10Z"
        fill={color || "#000"}
      />
      <path
        d="M10.499 10C10.499 10.276 10.275 10.5 9.99899 10.5C9.72299 10.5 9.49899 10.276 9.49899 10C9.49899 9.724 9.72299 9.5 9.99899 9.5C10.275 9.5 10.499 9.724 10.499 10Z"
        fill={color || "#000"}
      />
      <path
        d="M12.499 10C12.499 10.276 12.275 10.5 11.999 10.5C11.723 10.5 11.499 10.276 11.499 10C11.499 9.724 11.723 9.5 11.999 9.5C12.275 9.5 12.499 9.724 12.499 10Z"
        fill={color || "#000"}
      />
      <path
        d="M14.499 10C14.499 10.276 14.275 10.5 13.999 10.5C13.723 10.5 13.499 10.276 13.499 10C13.499 9.724 13.723 9.5 13.999 9.5C14.275 9.5 14.499 9.724 14.499 10Z"
        fill={color || "#000"}
      />
      <path
        d="M8.49899 12C8.49899 12.276 8.27499 12.5 7.99899 12.5C7.72299 12.5 7.49899 12.276 7.49899 12C7.49899 11.724 7.72299 11.5 7.99899 11.5C8.27499 11.5 8.49899 11.724 8.49899 12Z"
        fill={color || "#000"}
      />
      <path
        d="M10.499 12C10.499 12.276 10.275 12.5 9.99899 12.5C9.72299 12.5 9.49899 12.276 9.49899 12C9.49899 11.724 9.72299 11.5 9.99899 11.5C10.275 11.5 10.499 11.724 10.499 12Z"
        fill={color || "#000"}
      />
      <path
        d="M12.499 12C12.499 12.276 12.275 12.5 11.999 12.5C11.723 12.5 11.499 12.276 11.499 12C11.499 11.724 11.723 11.5 11.999 11.5C12.275 11.5 12.499 11.724 12.499 12Z"
        fill={color || "#000"}
      />
      <path
        d="M10.499 14C10.499 14.276 10.275 14.5 9.99899 14.5C9.72299 14.5 9.49899 14.276 9.49899 14C9.49899 13.724 9.72299 13.5 9.99899 13.5C10.275 13.5 10.499 13.724 10.499 14Z"
        fill={color || "#000"}
      />
    </svg>
  );
});
