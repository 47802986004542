import React, {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Tabs} from "components/Tabs";
import {inject, observer} from "mobx-react";
// local
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {IconLock, IconHourglass, IconPlusCircle} from "components/icons";
import {procurementStatuses} from "constants/buyer.consts";
import {LockValue} from "components/LockValue";
import {hasPermission} from "helpers/helper";
import {color} from "constants/color.consts";

const SingleRequestContent = inject("store")(
  observer(({store: {seller}}) => {
    const {
      request,
      requestToUsTabs,
      activeRequestTab,
      currentContract,
      isFinishedContract,
      isContract,
    } = seller;
    const location = useLocation();
    const navigate = useNavigate();
    const fromMessageNotifications =
      (request.id && location?.state?.messageProposalId === "seller") ||
      location?.search?.includes("?messageProposalId=seller");
    const unreadMessagesCount = request.MessagesStat?.unreadMessageCount;
    const totalMessagesCount = request.MessagesStat?.totalMessageCount;
    const isSubmitted = !!request.Offer?.id;
    const isStoppedProcurement =
      request?.Request?.ProjectItem?.status === procurementStatuses.STOPPED;

    const onSubmitted = () =>
      hasPermission(["READ_SELLER_RFTS"]) && seller.setActiveRequestTab(1);

    const tabLine = <div className="request-to-us__tabs__tab-line">|</div>;

    const CreateOfferButton = isSubmitted ? undefined : (
      <LockValue
        canPermissions={["READ_SELLER_RFTS"]}
        needLabel
        needShowModalToSubscribe
        addClassIcon="mb-1 mr-2 ml-0"
      >
        <ButtonTransparent
          nonTransparent
          small
          addClass="create-offer-button"
          name="CREATE_OFFER"
          icon={<IconPlusCircle />}
          disabled={isStoppedProcurement}
          onClick={onSubmitted}
        />
      </LockValue>
    );

    useEffect(() => {
      const contractId = location?.state?.contractId || request.Offer?.Contract?.id;
      location.state?.offerId && seller.setOfferId(location.state?.offerId);
      seller.setContractStatus(!!contractId);
      const isGettingContract = !!contractId && contractId !== currentContract?.id;

      // set Messages tab to active if you clicked on one of the top notifications
      seller.setActiveRequestTab(
        isGettingContract && activeRequestTab !== 2
          ? 2
          : location.state?.from === "offers"
          ? 1
          : fromMessageNotifications
          ? 3
          : activeRequestTab
      );
      contractId &&
        contractId !== currentContract?.id &&
        seller.getContract(contractId).catch(() => navigate("/contracts-to-us"));
    }, [
      request,
      fromMessageNotifications,
      activeRequestTab,
      currentContract,
      seller,
      location,
      navigate,
    ]);

    return (
      <Tabs
        tabs={requestToUsTabs}
        active={activeRequestTab}
        setActive={(id) => seller.setActiveRequestTab(id)}
        counts={[
          null,
          null,
          isFinishedContract ? (
            <IconLock width={16} height={16} />
          ) : isContract ? (
            <IconHourglass width={16} height={16} color={color.black} />
          ) : null,
          unreadMessagesCount ? unreadMessagesCount : totalMessagesCount ?? null,
        ]}
        redCounts={[null, null, null, unreadMessagesCount]}
        addClass="presentation-page__right-side__tabs request-to-us__tabs"
        leftContent={{OUR_OFFER: CreateOfferButton, CHAT: tabLine}}
      />
    );
  })
);

export default SingleRequestContent;
