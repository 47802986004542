import React from "react";
import {useTranslation} from "react-i18next";
// local
import {CustomTooltip} from "components/CustomTooltip";
import {IconUsers, IconInbox, IconPulse, IconEye} from "components/icons/index";

const RecipientsHead = ({hideAdditionalInfo}) => {
  const {t} = useTranslation();

  const RecipientsTooltip = ({text, icon}) => (
    <div className="recipients-tab_head__column">
      <CustomTooltip text={text}>
        <span>{icon}</span>
      </CustomTooltip>
    </div>
  );

  return (
    <div className="recipients-tab_head pt-8 pb-5">
      <div className="recipients-tab_head__empty"></div>
      <div className="recipients-tab_head__name">{t("COMPANY")}</div>
      {!hideAdditionalInfo && (
        <React.Fragment>
          <RecipientsTooltip text="READ" icon={<IconEye />} />
          <RecipientsTooltip text="INTERESTED" icon={<IconPulse />} />
          <RecipientsTooltip text="CONTACT_PERSON" icon={<IconUsers />} />
          <RecipientsTooltip text="HAS_OFFERED" icon={<IconInbox />} />
        </React.Fragment>
      )}
    </div>
  );
};

export default RecipientsHead;
