import React, {useState, useEffect, useCallback, useMemo} from "react";
import {inject, observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {Table} from "antd";
//local
import {
  IconDocumentDalux,
  IconFile,
  IconNotificationCircle,
  IconFolder,
  IconArrowIn,
  IconArrowOut,
} from "components/icons";
import {Modal} from "components/Modal";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {Preloader} from "components/Preloader";
import {daluxFolderType} from "constants/buyer.consts";

const isFolder = (entity) => !!entity?.folderName;
const isFolderWithFiles = (folder) => !!folder.children?.some((item) => !!item.fileId);

const EntityIcon = ({row}) => (
  <div className="df-row-center">
    {isFolder(row) ? (
      <IconFolder width="12" height="12" addClass="ml-1 mr-1" />
    ) : (
      <IconFile width="12" height="12" addClass="ml-1 mr-1" />
    )}
    <span>{row.name}</span>
  </div>
);

const AddDaluxLinkModal = inject("store")(
  observer(({store: {buyer}, revisionId, addLink}) => {
    const {currentRequest} = buyer;
    const [loading, setLoading] = useState(true);
    const [daluxProjectName, setDaluxProjectName] = useState("");
    const [daluxProjectId, setDaluxProjectId] = useState(null);
    const [foldersFilesList, setFolderFilesList] = useState([]);
    const [selectedLinks, setSelectedLinks] = useState([]);
    const {t} = useTranslation();

    const expandedFolders = useMemo(() => new Map(), []);
    const revisionLinks = useMemo(
      () => currentRequest?.documents?.find((item) => item.id === revisionId).links ?? [],
      [currentRequest, revisionId]
    );

    const columns = [
      {
        title: t("NAME"),
        dataIndex: "name",
        key: "name",
        render: (_, row) => <EntityIcon row={row} />,
      },
      {
        title: t("ADD_MODAL_DALUX_TABLE_VERSION_COLUMN"),
        dataIndex: "version",
        key: "version",
        width: "132px",
      },
      {
        title: t("ADD_MODAL_DALUX_TABLE_CHANGED_COLUMN"),
        dataIndex: "lastModified",
        key: "lastModified",
        width: "132px",
      },
    ];

    const createTreeList = useCallback((list, parentId) => {
      if (!list.length) return;
      const childrenList = list.filter((item) => item.parentFolderId === parentId);
      const innerList = list.filter((item) => item.parentFolderId !== parentId);
      return childrenList.map((item) => ({
        ...item,
        children: [...item.children, createTreeList(innerList, item.folderId)].flat()
          .length
          ? [...item.children, createTreeList(innerList, item.folderId)].flat()
          : null,
      }));
    }, []);

    const getDaluxFilesFolders = useCallback(
      async (daluxProjectId, fileAreaId, rootFolderName) => {
        const dataRequests = [
          buyer.getDaluxProjectFileAreaFiles(daluxProjectId, fileAreaId),
          buyer.getDaluxProjectFileAreaFolders(daluxProjectId, fileAreaId),
          buyer.getDaluxProjectFileAreaVersionSets(
            daluxProjectId,
            fileAreaId,
            currentRequest.Project.id
          ),
        ];

        const data = (await Promise.allSettled(dataRequests))
          .filter((item) => item.status === "fulfilled")
          .map((item) => item.value);
        const [filesData, foldersData, versionSetsData] = data;

        const files = new Map();
        filesData.forEach((file) => {
          const data = {
            ...file.data,
            name: file.data.fileName,
            revisionId: file.data.fileRevisionId,
            key: file.data.fileId,
          };
          files.set(
            file.data.folderId,
            files.has(file.data.folderId)
              ? [...files.get(file.data.folderId), data]
              : [data]
          );
        });
        const folders = foldersData.map((folder) => ({
          ...folder,
          name: folder.folderName,
          type: daluxFolderType.FOLDER,
          key: `${fileAreaId}_${folder.folderId}`,
        }));
        const filesFolders = folders.slice(1).map((folder) => ({
          ...folder,
          children: files.get(folder.folderId) ?? [],
        }));
        const filesVersionSets =
          versionSetsData?.map((versionSet) => ({
            ...versionSet.data,
            folderId: versionSet.data.versionSetId,
            folderName: versionSet.data.name,
            type: daluxFolderType.VERSION_SET,
            key: `${fileAreaId}_${versionSet.data.versionSetId}`,
            children: versionSet.data.children.map((file) => ({
              ...file.data,
              name: file.data.fileName,
              revisionId: file.data.fileRevisionId,
              folderId: versionSet.data.versionSetId,
              key: `${file.data.fileId}_${versionSet.data.versionSetId}`,
            })),
          })) ?? [];
        const finalList = createTreeList(filesFolders, folders[0].folderId);
        const rootFolder = {
          ...folders[0],
          key: `${fileAreaId}_${folders[0].folderId}`,
          name: rootFolderName,
          folderName: rootFolderName,
          children: finalList,
        };
        const versionSetRootFolder = {
          folderId: "23428071861",
          key: `${fileAreaId}_23428071861`,
          name: t("ADD_MODAL_DALUX_TABLE_VERSION_SETS_ROOT_FOLDER"),
          folderName: t("ADD_MODAL_DALUX_TABLE_VERSION_SETS_ROOT_FOLDER"),
          type: daluxFolderType.VERSION_SET,
          children: filesVersionSets,
        };
        return [rootFolder, versionSetRootFolder];
      },
      [buyer, createTreeList, currentRequest.Project.id, t]
    );

    const getDaluxInfo = useCallback(async () => {
      const daluxProjectId = currentRequest.Project.daluxProjectId;
      setDaluxProjectId(daluxProjectId);
      const data = await buyer.getDaluxProject(daluxProjectId);
      setDaluxProjectName(data.projectName);
      const areas = await buyer.getDaluxProjectFileAreas(daluxProjectId);
      const fillFoldersFileList = areas.map((area) => {
        return new Promise(async (resolve, reject) => {
          try {
            const folders = await getDaluxFilesFolders(
              daluxProjectId,
              area.fileAreaId,
              area.fileAreaName
            );
            resolve(folders);
          } catch (error) {
            reject(error);
          }
        });
      });

      const list = await Promise.all(fillFoldersFileList);
      setFolderFilesList(...list);
    }, [buyer, currentRequest.Project, getDaluxFilesFolders]);

    const customExpandIcon = ({expanded, record, onExpand}) => {
      if (!record.children?.length) return <div className="wdth-18"></div>;
      return expanded ? (
        <IconArrowOut
          addClass="mr-1 cpointer"
          onClick={(e) => {
            expandedFolders.delete(record.folderId);
            onExpand(record, e);
          }}
        />
      ) : (
        <IconArrowIn
          addClass="mr-1 cpointer"
          onClick={(e) => {
            isFolderWithFiles(record) &&
              expandedFolders.set(record.folderId, {
                name: record.name,
                type: record.type,
              });
            onExpand(record, e);
          }}
        />
      );
    };

    const handleRowSelect = (rows) => {
      const rowsFolders = rows
        .filter(isFolder)
        .map((folder) => [folder.folderId, {name: folder.name, type: folder.type}]);
      const selectedFilesFolders = new Map([...rowsFolders, ...expandedFolders]);
      const reorganizedRows = rows
        .filter((row) => !isFolder(row))
        .map((row) => {
          const isVersionSet =
            selectedFilesFolders.get(row.folderId)?.type === daluxFolderType.VERSION_SET;
          const revisionId = isVersionSet ? row.revisionId : "null";
          const folderName = selectedFilesFolders.get(row.folderId)?.name ?? "null";
          return `daluxFile/${row.fileName}/${daluxProjectId}/${row.fileAreaId}/${row.fileId}/${revisionId}/${row.folderId}/${folderName}`;
        });
      setSelectedLinks(reorganizedRows);
    };

    // rowSelection objects indicates the need for row selection
    const rowSelection = {
      getCheckboxProps: (record) => ({
        disabled: !(record.children || record.fileId), //disable folder checkbox without files
      }),
      onSelect: (record, selected, selectedRows) => handleRowSelect(selectedRows),
    };

    const handleSubmit = () => {
      const addedLinks = selectedLinks
        .filter((item) => !revisionLinks.map((link) => link.url).includes(item))
        .map((url) => ({url}));
      addLink(addedLinks);
      buyer.setAddDaluxLinkModalStatus(false);
    };

    useEffect(() => {
      getDaluxInfo().finally(() => setLoading(false));
    }, [getDaluxInfo]);

    return (
      !!daluxProjectName && (
        <Modal
          titleIcon={<IconDocumentDalux addClass="mr-4" />}
          titleText={daluxProjectName}
          whiteTitle
          percentageWide="85%"
          noCenterContent
          noCloseButton
          noCloseOnEsc
          rounded
          onClose={() => buyer.setAddDaluxLinkModalStatus(false)}
        >
          <div className="add-dalux-link-modal__content">
            <div className="add-dalux-link-modal__file-list">
              {loading ? (
                <Preloader addClass="preloader250" />
              ) : (
                <Table
                  columns={columns}
                  rowSelection={{
                    ...rowSelection,
                    checkStrictly: false,
                    hideSelectAll: true,
                  }}
                  expandIcon={(props) => customExpandIcon(props)}
                  dataSource={foldersFilesList}
                  pagination={false}
                  rowClassName={(_, index) => (index % 2 === 1 ? "colored" : "")}
                  scroll={{y: "50vh"}}
                />
              )}
            </div>

            <div className="df-row-jce-center mt-4 ml-1">
              <IconNotificationCircle color="black" />
              <div className="info-blob ml-2">{t("ADD_MODAL_DALUX_INFO_BLOB")}</div>
              <ButtonTransparent
                name="CANCEL"
                color="red"
                medium
                onClick={() => buyer.setAddDaluxLinkModalStatus(false)}
                addClass="mr-4"
              />
              <ButtonTransparent
                name="ADD_SELECTED"
                medium
                nonTransparent
                onClick={handleSubmit}
                disabled={!selectedLinks.length}
              />
            </div>
          </div>
        </Modal>
      )
    );
  })
);

export default AddDaluxLinkModal;
