// ======================================
// Working with data outside of the procurement process. Data
// ======================================

import {makeAutoObservable} from "mobx";
//local
import axiosInstance from "../axios";
import {getStorage} from "../helpers/storage";
import {directionReplacement, formFileData} from "helpers/helper";
import {showSuccess} from "helpers/notifications.helper";
import {LANGUAGE} from "constants/language.constants";
import {toRequestId, toRequestToUsId} from "constants/routes.const";

class ClientStore {
  // STATE. Observable and computed part
  showSidebar = false; // sidebar display status
  language = getStorage(LANGUAGE) ?? "sv"; // user language
  rfpMessages = []; // messages into RFP process

  constructor() {
    makeAutoObservable(this);
  }

  // ACTIONS
  // switching the sidebar display
  setShowSidebar(value) {
    this.showSidebar = value;
  }

  // download the contract pdf-file
  async downloadPDF(id, side) {
    const data = await axiosInstance.get(`rfp/${side}/contract/${id}/pdf`, {
      responseType: "blob",
    });
    return data.data;
  }

  // download the files from one specific column by one zip file
  async downloadZIP(files, revisionId) {
    try {
      const {data} = await axiosInstance.post(`files/zip`, {
        files,
        revisionId,
      });
      return data;
    } catch (e) {
      throw e;
    }
  }

  setLanguage(value) {
    this.language = value;
  }

  async getMessages() {
    try {
      const {data} = await axiosInstance.get("our-profile/notifications/messages");
      const rezult = data
        .map((item) =>
          item.Proposals?.map((proposal) => ({
            id: item.isBuyer ? item.id : proposal.id,
            rfpTitle: item.name,
            organizationName: proposal.Organization.name,
            updatedAt: proposal.MessagesStat.recentUpdatedMessageAt,
            link: item.isBuyer ? toRequestId(item.id) : toRequestToUsId(proposal.id),
            messageProposalId: item.isBuyer ? proposal.Organization.id : "seller",
          }))
        )
        .flat()
        .sort((a, b) => Date.parse(b.updatedAt) - Date.parse(a.updatedAt));
      this.rfpMessages = rezult;
    } catch (error) {
      throw error;
    }
  }

  // ****** WORKING WITH SUPPLIER QUALIFICATION ******

  // getting qualifications questions list of own organization
  getQualificationQuestions() {
    return axiosInstance.get("qualification/buyer/questions");
  }

  // uploading file for qualification question
  qualificationQuestionUploadFile(file) {
    return axiosInstance
      .post("qualification/buyer/upload", formFileData(file.file.originFileObj))
      .then(({data}) => data);
  }

  // updating qualifications questions list of own organization
  saveQualificationQuestions(values) {
    return axiosInstance.put("qualification/buyer/questions", values);
  }

  // starting qualification for specific supplier
  startQualification(SupplierOrganizationId) {
    return axiosInstance.post("qualification/buyer/start", {SupplierOrganizationId});
  }

  // confirming qualifications answers for specific qualification
  confirmQualificationAnswers(qualificationId) {
    return axiosInstance.post(`qualification/buyer/${qualificationId}/confirm`);
  }

  // removing (rejecting) qualifications answers for specific qualification
  removeQualificationAnswers(qualificationId, payload) {
    return axiosInstance.post(`qualification/buyer/${qualificationId}/remove`, payload);
  }

  // getting qualification answers list of own organization
  getQualificationAnswersList(filterParams) {
    const direction = directionReplacement(filterParams);
    const params = {...filterParams, direction};
    return axiosInstance.get("qualification/seller/search", {params});
  }

  // getting specific qualification answer
  getQualificationAnswers(qualificationId, side) {
    return axiosInstance.get(`qualification/${side}/${qualificationId}`);
  }

  // uploading file for qualification answer
  qualificationAnswerUploadFile(file) {
    return axiosInstance
      .post(`qualification/seller/upload`, formFileData(file.file.originFileObj))
      .then(({data}) => data);
  }

  // updating specific qualification answer
  saveQualificationAnswers(qualificationId, values) {
    return axiosInstance.put(`qualification/seller/${qualificationId}`, values);
  }

  // sending specific qualification answer
  sendQualificationAnswers(qualificationId) {
    return axiosInstance.post(`qualification/seller/${qualificationId}/send`);
  }

  // ****** WORKING WITH USER SMART TEMPLATES ******

  // getting smart templates list of current user
  getUserSmartTemplates() {
    return axiosInstance.get("user/rft-smart-form-template").then(({data}) => data);
  }

  // creating smart templates list of current user
  createUserSmartTemplate(values) {
    return axiosInstance.post("user/rft-smart-form-template", values);
  }

  // getting specific smart template of current user
  getUserSmartTemplate(smartTemplateId) {
    return axiosInstance
      .get(`user/rft-smart-form-template/${smartTemplateId}`)
      .then(({data}) => data);
  }

  // updating specific smart template of current user
  saveUserSmartTemplate(smartTemplateId, values) {
    return axiosInstance.put(`user/rft-smart-form-template/${smartTemplateId}`, values);
  }

  // deleting specific smart template of current user
  removeUserSmartTemplate(smartTemplateId) {
    return axiosInstance.delete(`user/rft-smart-form-template/${smartTemplateId}`);
  }

  // ****** WORKING WITH USER MONITORING ORGANIZATIONS ******

  // getting specific company for monitoring of current user
  getUserMonitoredCompany(companyId) {
    return axiosInstance
      .get(`user/supplier-monitoring/${companyId}`)
      .then(({data}) => data);
  }

  // setting specific company for monitoring of current user
  setUserMonitoredCompany(companyId) {
    return axiosInstance
      .post(`user/supplier-monitoring/${companyId}`)
      .then(() => showSuccess());
  }

  // deleting specific company for monitoring of current user
  removeUserMonitoredCompany(companyId) {
    return axiosInstance
      .delete(`user/supplier-monitoring/${companyId}`)
      .then(() => showSuccess());
  }

  // ****** WORKING WITH USER ERRORS ******

  // deleting specific company for monitoring of current user
  sendError(error, payload) {
    return axiosInstance.post("system-log/error", {
      source: "SYSTEM_LOG_SOURCE_FRONTEND",
      message: error?.response?.data?.error ?? "Unknown error",
      rawData: {
        route: error?.response?.data?.route ?? "Unknown route",
        data: payload,
      },
    });
  }
}

const client = new ClientStore();
export default client;
