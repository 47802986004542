export const dateFormat = (value) => {
  if (value) {
    let d = new Date(value),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  return "";
};

export const timeFormat = (value) => {
  let options = {
    hour: "numeric",
    minute: "numeric",
  };
  if (value) {
    let d = new Date(value);
    return d.toLocaleString("sv", options);
  }
  return "";
};

export const dateTimeFormat = (value, divider = "|") =>
  value && `${dateFormat(value)} ${divider} ${timeFormat(value)}`;

export const monthYearFormat = (value, language) => {
  let options = {
    month: "long",
    year: "numeric",
  };
  if (value && language) return new Date(value).toLocaleString(language, options);
  return "";
};

export const getWeek = (value) => {
  let date = new Date(value);
  date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
  let week1 = new Date(date.getFullYear(), 0, 4);
  return (
    1 +
    Math.round(
      ((date.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7
    )
  );
};

export const getWeekYear = (value) => {
  let date = new Date(value);
  date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
  return date.getFullYear();
};
