import React, {useState, useEffect, useCallback, useMemo} from "react";
import {inject, observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {useLocation, useParams} from "react-router-dom";
import {v4 as uuidv4} from "uuid";
import {debounce} from "lodash";
//local
import TableTitleResult from "components/Table/TableTitleResult";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import ProposalsHeadProposals from "components/requestCard/ProposalsHeadProposals";
import ProposalCard from "../components/ProposalCard";
import ProposalItem from "components/requestCard/ProposalItem";
import ManualProposalItem from "components/requestCard/ManualProposalItem";
import {ModalManualOffer} from "components/modals/ModalManualOffer";
import {Preloader} from "components/Preloader";
import {Explanation} from "components/Explanation";
import {LockValue} from "components/LockValue";
import {hasPermission} from "helpers/helper";
import {IconDownload, IconPushPin, IconExpand, IconEyeCrossed} from "components/icons";
import {copyToClipboard, saveDocument} from "helpers/helper";
import {offerMedalRating} from "constants/buyer.consts";
import {REQUESTS} from "constants/routes.const";
import {ShareButton} from "components/buttons/ShareButton";

export const Proposals = inject("store")(
  observer(({store: {buyer, clientStore}}) => {
    const {currentRequest, isViewSpecificOffer, currentOffer} = buyer;
    const {language} = clientStore;
    const {t} = useTranslation();
    const location = useLocation();
    const {offerId} = useParams();

    const [isAdjustments, setAdjustments] = useState(true);
    const [isAnswers, setAnswers] = useState(true);
    const [isChecks, setChecks] = useState(true);
    const [isDownload, setIsDownload] = useState(false);
    const [proposals, setProposals] = useState([]);
    const [publicProposals, setPublicProposals] = useState([]);
    const [manualProposals, setManualProposals] = useState([]);
    const [linkToSpecificOffer, setLinkToSpecificOffer] = useState(
      location?.state?.offerId ?? offerId
    );
    const [loading, setLoading] = useState(true);
    const [isManualOfferModal, setManualOfferModal] = useState(null);
    const [hoveredProposalId, setHoveredProposalId] = useState(null);
    const [editedTitle, setEditedTitle] = useState(null);

    const adjustmentsTitlesIds = useMemo(
      () => currentRequest.adjustmentTitles?.map((item) => item.id) ?? [],
      [currentRequest.adjustmentTitles]
    );

    const checkTitlesIds = useMemo(
      () => currentRequest?.checkTitles?.map((check) => check.id) ?? [],
      [currentRequest.checkTitles]
    );

    const isOffersHidden = useMemo(
      () => currentRequest?.isOffersHidden,
      [currentRequest]
    );
    const offerHiddenIcon = useCallback(
      () => (isOffersHidden ? <IconEyeCrossed /> : null),
      [isOffersHidden]
    );

    const smartFormQuestions = currentRequest?.smartFormQuestions?.filter(
      (item) => item.type !== "heading"
    );

    const totalProposals =
      proposals.length + manualProposals.length + publicProposals.length;

    const copyLink = () =>
      copyToClipboard(
        `${window.location.origin}/public/requests/${currentRequest.id}/offers`,
        true
      );

    const getOffers = useCallback(
      () =>
        buyer
          .getCombinedOffers(currentRequest.id)
          .then((data) => {
            setProposals(data.buyerOffers.filter((offer) => !offer.Proposal.isPublic));
            setPublicProposals(
              data.buyerOffers.filter((offer) => offer.Proposal.isPublic)
            );
            setManualProposals(data.manualOffers);
          })
          .finally(() => setLoading(false)),
      [buyer, currentRequest.id]
    );

    const downloadXLSX = async () => {
      setIsDownload(true);
      const fileName = `offers-${currentRequest.id}.xlsx`;
      buyer
        .downloadXLSX(currentRequest.id)
        .then((data) =>
          saveDocument(
            data,
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            fileName
          )
        )
        .finally(() => setIsDownload(false));
    };

    const handleBackToProposals = () => {
      setLinkToSpecificOffer(undefined);
      window.history.replaceState(null, null, `${REQUESTS}/${currentRequest.id}`);
      buyer.setViewSpecificOfferStatus(false);
    };

    const handleChangeCollapse = (type) => {
      if (type === "adjustments") setAdjustments((prevState) => !prevState);
      if (type === "answers") setAnswers((prevState) => !prevState);
      if (type === "checks") setChecks((prevState) => !prevState);
    };

    const handleAddEntity = (type, label) => {
      if (type === "adjustments") {
        const adjustment = {id: uuidv4(), label};
        buyer.updateCurrentRequestInfo(
          {
            adjustmentTitles: [...(currentRequest.adjustmentTitles ?? []), adjustment],
          },
          false
        );
        return adjustment;
      }
      if (type === "checks") {
        const check = {id: uuidv4(), label};
        buyer.updateCurrentRequestInfo(
          {
            checkTitles: [...(currentRequest.checkTitles ?? []), check],
          },
          false
        );
        return check;
      }
    };

    const handleChangeTitle = async (type, label, id, action, isNoBackendChanges) => {
      if (action !== "delete" && !label) return;
      if (type === "adjustments")
        isNoBackendChanges
          ? buyer.updateCurrentRequestInfo({
              adjustmentTitles:
                action === "delete"
                  ? currentRequest.adjustmentTitles.filter((item) => item.id !== id)
                  : currentRequest.adjustmentTitles.map((item) =>
                      item.id === id ? {...item, label} : item
                    ),
            })
          : await buyer.addRfpAdjustmentTitle(
              currentRequest.id,
              action === "delete"
                ? currentRequest.adjustmentTitles.filter((item) => item.id !== id)
                : currentRequest.adjustmentTitles.map((item) =>
                    item.id === id ? {...item, label} : item
                  )
            );
      if (type === "checks")
        isNoBackendChanges
          ? buyer.updateCurrentRequestInfo({
              checkTitles:
                action === "delete"
                  ? currentRequest.checkTitles.filter((item) => item.id !== id)
                  : currentRequest.checkTitles.map((item) =>
                      item.id === id ? {...item, label} : item
                    ),
            })
          : await buyer.addRfpCheckTitle(
              currentRequest.id,
              action === "delete"
                ? currentRequest.checkTitles.filter((item) => item.id !== id)
                : currentRequest.checkTitles.map((item) =>
                    item.id === id ? {...item, label} : item
                  )
            );
    };

    const updateProposals = (values, id, isPublic) => {
      const changedProposals = (isPublic ? publicProposals : proposals).map((proposal) =>
        proposal.id === id ? {...proposal, ...values} : proposal
      );
      (isPublic ? setPublicProposals : setProposals)(changedProposals);
    };

    const handleChangeAdjustment = debounce(async (type, values, id) => {
      if (type === "manual") {
        const adjustmentValues = await buyer.addManualOfferAdjustmentValue(
          currentRequest.id,
          id,
          values
        );
        setManualProposals(
          manualProposals.map((proposal) =>
            proposal.id === id ? {...proposal, adjustmentValues} : proposal
          )
        );
      } else {
        const adjustmentValues = await buyer.addOfferAdjustmentValue(id, values);
        updateProposals({adjustmentValues}, id, type === "public");
      }
    }, 800);

    const handleChangeCheck = debounce(async (type, values, id) => {
      if (type === "manual") {
        const checkValues = await buyer.addManualOfferCheckValue(
          currentRequest.id,
          id,
          values
        );
        setManualProposals(
          manualProposals.map((proposal) =>
            proposal.id === id ? {...proposal, checkValues} : proposal
          )
        );
      } else {
        const checkValues = await buyer.addOfferCheckValue(id, values);
        updateProposals({checkValues}, id, type === "public");
      }
    }, 800);

    const handleChangeRating = async (type, place, id) => {
      if (type === "manual") {
        const {medal} = await buyer.updateManualOffer(currentRequest.id, id, {
          medal: offerMedalRating[place] ?? null,
        });
        setManualProposals(
          manualProposals.map((proposal) =>
            proposal.id === id ? {...proposal, medal} : proposal
          )
        );
      } else {
        const {medal} = await buyer.saveOffer(
          {medal: offerMedalRating[place] ?? null},
          id
        );
        updateProposals({medal}, id, type === "public");
      }
    };

    const handleUpdateNote = debounce(async (type, note, id) => {
      if (type === "manual") {
        const {buyerNote} = await buyer.updateManualOffer(currentRequest.id, id, {
          buyerNote: note,
        });
        setManualProposals(
          manualProposals.map((proposal) =>
            proposal.id === id ? {...proposal, buyerNote} : proposal
          )
        );
      } else {
        const {buyerNote} = await buyer.saveOffer({buyerNote: note}, id);
        updateProposals({buyerNote}, id, type === "public");
      }
    }, 800);

    const setFullscreenMode = () => {
      const requestNode = document.querySelector(".request.__wrapper");
      const mainPageNode = document.querySelector(".main-content_wrapper");
      const isFullscreenMode = requestNode.classList.contains("request-fullscreen-mode");
      if (isFullscreenMode) {
        requestNode.classList.remove("request-fullscreen-mode");
        mainPageNode.classList.remove("hide-on-fullscreen-mode");
      } else {
        requestNode.classList.add("request-fullscreen-mode");
        mainPageNode.classList.add("hide-on-fullscreen-mode");
      }
    };

    useEffect(() => {
      if (!isViewSpecificOffer && currentRequest?.id) {
        !!linkToSpecificOffer
          ? buyer
              .getOffer(linkToSpecificOffer)
              .then(() => buyer.setViewSpecificOfferStatus(true))
              .catch(() => {
                window.history.replaceState(
                  null,
                  null,
                  `${REQUESTS}/${currentRequest.id}`
                );
                getOffers(currentRequest.id);
              })
          : getOffers(currentRequest.id);
      }
    }, [
      buyer,
      currentRequest,
      currentRequest.id,
      linkToSpecificOffer,
      isViewSpecificOffer,
      getOffers,
    ]);

    useEffect(() => {
      isViewSpecificOffer && !linkToSpecificOffer && buyer.getOffer(currentOffer?.id);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <div className="proposals-tab_wrapper">
        {isViewSpecificOffer ? (
          <ProposalCard backToProposals={handleBackToProposals} />
        ) : (
          <div>
            {loading && <Preloader addClass="preloader100" />}
            {!loading && (
              <>
                {!!totalProposals ? (
                  <>
                    <TableTitleResult
                      addClass={"mt-8 mb-6"}
                      title={"OFFERS"}
                      totalItems={totalProposals}
                    >
                      <ShareButton
                        addClass="feed-post-view__share-button"
                        name="COPY_PUBLIC_LINK"
                        onClickCopy={copyLink}
                      />
                      <ButtonTransparent
                        name={t("DOWNLOAD_EXCEL")}
                        addClass="ml-8"
                        small
                        icon={<IconDownload />}
                        iconRight={offerHiddenIcon()}
                        loading={isDownload}
                        disabled={isOffersHidden}
                        disabledTooltip={t("EXPLANATION_OFFERS_HIDDEN_NO_DOWNLOADS")}
                        onClick={downloadXLSX}
                      />
                      <LockValue
                        canPermissions={["CREATE_RFT_OFFERS_MANUAL_OFFER"]}
                        needLabel
                        noLockIcon
                        dimmedWhenLocked
                      >
                        <ButtonTransparent
                          name={t("ADD_MANUALLY")}
                          addClass="ml-8 add-manual-offer"
                          small
                          icon={<IconPushPin />}
                          iconRight={offerHiddenIcon()}
                          disabled={isOffersHidden}
                          disabledTooltip={t(
                            "EXPLANATION_OFFERS_HIDDEN_NO_MANUAL_OFFERS"
                          )}
                          onClick={() =>
                            hasPermission(["CREATE_RFT_OFFERS_MANUAL_OFFER"]) &&
                            setManualOfferModal("new-offer")
                          }
                        />
                      </LockValue>

                      <ButtonTransparent
                        name={t("RFT_PROPOSALS_LIST_FULLSCREEN_BUTTON")}
                        addClass="ml-8"
                        small
                        icon={<IconExpand />}
                        onClick={setFullscreenMode}
                      />
                    </TableTitleResult>
                    <div className="df">
                      <ProposalsHeadProposals
                        adjustmentsTitles={currentRequest.adjustmentTitles}
                        checkTitles={currentRequest.checkTitles}
                        questions={smartFormQuestions}
                        currencyCode={currentRequest.CurrencyCode}
                        isShowAdjustments={isAdjustments}
                        isShowAnswers={isAnswers}
                        isShowChecks={isChecks}
                        onChangeCollapse={handleChangeCollapse}
                        editedTitle={editedTitle}
                        onSetEditedTitle={setEditedTitle}
                        onChangeTitle={handleChangeTitle}
                        onClickAdd={handleAddEntity}
                      />
                      <div className="proposals-tab_items">
                        {proposals.map((proposal) => (
                          <ProposalItem
                            key={proposal.id}
                            proposal={proposal}
                            language={language}
                            isShowAdjustments={isAdjustments}
                            isShowAnswers={isAnswers}
                            isShowChecks={isChecks}
                            adjustmentsIds={adjustmentsTitlesIds}
                            clickable={!isOffersHidden}
                            isHidedInfo={isOffersHidden}
                            offersClosedAt={currentRequest.closedAt}
                            budgetValue={currentRequest?.Project?.ProjectItem?.costBudget}
                            questions={smartFormQuestions}
                            checkIds={checkTitlesIds}
                            onChangeNote={handleUpdateNote}
                            onChangeAdjustment={handleChangeAdjustment}
                            onChangeCheck={handleChangeCheck}
                            onChangeRating={handleChangeRating}
                            onMouseEnter={() => setHoveredProposalId(proposal.id)}
                            onMouseLeave={() => setHoveredProposalId(null)}
                            isHovered={hoveredProposalId === proposal.id}
                            isEditingTitleMode={!!editedTitle}
                          />
                        ))}
                        {manualProposals.map((proposal) => (
                          <ManualProposalItem
                            key={proposal.id}
                            proposal={proposal}
                            language={language}
                            isShowAdjustments={isAdjustments}
                            isShowAnswers={isAnswers}
                            isShowChecks={isChecks}
                            adjustmentsIds={adjustmentsTitlesIds}
                            answersCount={smartFormQuestions.length}
                            budgetValue={currentRequest?.Project?.ProjectItem?.costBudget}
                            checkIds={checkTitlesIds}
                            clickable={true}
                            onMouseEnter={() => setHoveredProposalId(proposal.id)}
                            onMouseLeave={() => setHoveredProposalId(null)}
                            isHovered={hoveredProposalId === proposal.id}
                            isEditingTitleMode={!!editedTitle}
                            onChangeAdjustment={handleChangeAdjustment}
                            onChangeNote={handleUpdateNote}
                            onChangeCheck={handleChangeCheck}
                            onChangeRating={handleChangeRating}
                            onClick={() => setManualOfferModal(proposal.id)}
                          />
                        ))}
                        {publicProposals.map((proposal) => (
                          <ProposalItem
                            key={proposal.id}
                            proposal={proposal}
                            language={language}
                            isShowAdjustments={isAdjustments}
                            isShowAnswers={isAnswers}
                            isShowChecks={isChecks}
                            adjustmentsIds={adjustmentsTitlesIds}
                            clickable={!isOffersHidden}
                            isHidedInfo={isOffersHidden}
                            offersClosedAt={currentRequest.closedAt}
                            budgetValue={currentRequest?.Project?.ProjectItem?.costBudget}
                            questions={smartFormQuestions}
                            checkIds={checkTitlesIds}
                            onChangeNote={handleUpdateNote}
                            onChangeAdjustment={handleChangeAdjustment}
                            onChangeCheck={handleChangeCheck}
                            onChangeRating={handleChangeRating}
                            onMouseEnter={() => setHoveredProposalId(proposal.id)}
                            onMouseLeave={() => setHoveredProposalId(null)}
                            isHovered={hoveredProposalId === proposal.id}
                            isEditingTitleMode={!!editedTitle}
                            isPublic
                          />
                        ))}
                      </div>
                    </div>
                  </>
                ) : (
                  <React.Fragment>
                    <ButtonTransparent
                      name={t("ADD_MANUALLY")}
                      addClass="mla mb-4"
                      small
                      icon={<IconPushPin />}
                      iconRight={offerHiddenIcon()}
                      disabled={isOffersHidden}
                      disabledTooltip={t("EXPLANATION_OFFERS_HIDDEN_NO_MANUAL_OFFERS")}
                      onClick={() => setManualOfferModal("new-offer")}
                    />
                    <Explanation
                      text="BEAVER_SAYS_YOU_HAVE_NO_OFFERS"
                      fitWidth
                      center
                      textCenter
                      thinVPadding
                    />
                  </React.Fragment>
                )}
              </>
            )}
            {!!isManualOfferModal && (
              <ModalManualOffer
                requestId={currentRequest?.id}
                offerId={isManualOfferModal}
                onClose={(needUpdate) => {
                  needUpdate && getOffers();
                  setManualOfferModal(null);
                }}
              />
            )}
          </div>
        )}
      </div>
    );
  })
);
