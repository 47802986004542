import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
// local
import {InfoTooltip} from "components/InfoTooltip";
import EmptyOrNoCompanyCreditData from "components/EmptyOrNoCompanyCreditData";
import PresentationCardWrapper from "../PresentationCardWrapper";
import {IconBandaid, IconScale} from "components/icons";
import {LockValue} from "components/LockValue";
import {formattedNumber} from "helpers/number.formats";
import {dateFormat} from "helpers/datetime";

const TaxReliefHistory = ({companyData, hasFirstUpdateData}) => {
  const {t} = useTranslation();

  const updatedCreditSafeCredit = useMemo(
    () => companyData?.Company?.updatedCreditSafeCredit,
    [companyData?.Company]
  );

  const taxRespiteHistory = useMemo(
    () => companyData?.Company?.companyCreditReport?.taxRespiteHistory || [],
    [companyData?.Company?.companyCreditReport?.taxRespiteHistory]
  );

  const hasTaxReliefHistory = useMemo(
    () => !!taxRespiteHistory?.length,
    [taxRespiteHistory]
  );
  // Find the most recent tax relief amount by sorting dates
  const mostRecentTaxRelief = hasTaxReliefHistory
    ? taxRespiteHistory.sort((a, b) => new Date(b?.date) - new Date(a?.date))?.[0]?.amount
    : 0;

  const mostRecentTaxRespiteHistoryDate = useMemo(
    () => taxRespiteHistory?.[0]?.date,
    [taxRespiteHistory]
  );

  const basicData = companyData?.Company?.basic;
  const hasRevenue = !!basicData?.revenue;

  // Calculate raw percentage of companies revenue - convert revenue from kSEK to SEK by multiplying by 1000
  const percentageOfTurnover =
    hasRevenue && hasTaxReliefHistory && mostRecentTaxRelief
      ? (mostRecentTaxRelief / (basicData?.revenue * 1000)) * 100
      : null;

  // Format percentagefor display
  const formattedPercentage = percentageOfTurnover ? percentageOfTurnover.toFixed(1) : "";

  // Check if CreditSafe data was updated after tax relief feature release (assuming March 19, 2025)
  // We'll use csDataUpdatedAfterFeatureRelease further down to control the messaging for legacy CS data
  const csDataUpdatedAfterFeatureRelease = updatedCreditSafeCredit
    ? new Date(updatedCreditSafeCredit) > new Date("2025-03-19")
    : false;

  return (
    <PresentationCardWrapper
      icon={<IconBandaid />}
      title={t("FINANCIALS_SECTION_TAX_RELIEF_HEADING")}
      titleToolTip="FINANCIALS_SECTION_TAX_RELIEF_EXPLAINER"
      addClass="tax-relief tax-relief-history"
    >
      <LockValue
        canPermissions={["READ_OTHER_ANNUAL_REPORT"]}
        noHideOurInfo
        fullSubcribeButton
        needMiniSibscribeButton
      >
        {updatedCreditSafeCredit != null ? (
          <div className="presentation-card tax-relief-history df-column">
            {hasTaxReliefHistory ? (
              <React.Fragment>
                <div className="custom-table_head">
                  <div className="custom-table_head__title">
                    {t("FINANCIALS_SECTION_TAX_RELIEF_TABLE_HEADING_TOTAL")}
                  </div>
                  <div className="custom-table_head__title">
                    {t("FINANCIALS_SECTION_TAX_RELIEF_TABLE_HEADING_DATE_CHECKED")}
                  </div>
                </div>
                {taxRespiteHistory?.map((item) => (
                  <div
                    key={`${item?.date}${item?.amount}`}
                    className="custom-table_body__item"
                  >
                    <div className="custom-table_body__value">
                      {formattedNumber(item?.amount)}
                    </div>
                    <div className="custom-table_body__value df-row-center">
                      {dateFormat(new Date(item?.date))}
                      {/* Only show percentage for the most recent entry */}
                      {item?.date === mostRecentTaxRespiteHistoryDate && (
                        <span className="overview-chunk df-row-center ml-4">
                          <IconScale />
                          <span className="ml-1 mr-2">{formattedPercentage}%</span>
                          <InfoTooltip tooltip="FINANCIALS_SECTION_TAX_RELIEF_TOTAL" />
                        </span>
                      )}
                    </div>
                  </div>
                ))}
              </React.Fragment>
            ) : (
              <div>
                <i>
                  {/* Special check to see if CS data is legacy or if there
                      actually is no tax respite history */}
                  {csDataUpdatedAfterFeatureRelease
                    ? t("FINANCIALS_SECTION_TAX_RELIEF_NO_HISTORY")
                    : t("FINANCIALS_SECTION_TAX_RELIEF_NO_HISTORY_LEGACY")}
                </i>
              </div>
            )}
          </div>
        ) : (
          <div className="presentation-card tax-relief-history no-taxrelief-data df-column">
            <EmptyOrNoCompanyCreditData hasFirstUpdateData={hasFirstUpdateData} />
          </div>
        )}
      </LockValue>
    </PresentationCardWrapper>
  );
};

export default TaxReliefHistory;
