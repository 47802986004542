import React from "react";
import {Tooltip} from "antd";
import {useTranslation} from "react-i18next";
// local
import cn from "classnames";

export const CustomTooltip = ({
  children,
  text,
  color,
  disabled,
  visible,
  placement,
  centerText,
  noTranslate,
  noWrapText,
  addClass,
}) => {
  const {t} = useTranslation();

  return (
    <Tooltip
      placement={placement}
      title={disabled ? null : noTranslate ? text : t(text)}
      color={color}
      open={visible}
      overlayClassName={cn("custom-tooltip", {
        [addClass]: !!addClass,
        "center-text": !!centerText,
        "no-wrap-text": !!noWrapText,
      })}
    >
      {children}
    </Tooltip>
  );
};
