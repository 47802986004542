import React, {useState, useEffect, useMemo} from "react";
import {inject, observer} from "mobx-react";
import {useNavigate, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
// local
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {IconDocumentPen, IconLock, IconBriefcase} from "components/icons";
import LoadDocsColumn from "components/LoadDocsColumn";
import {Tabs} from "components/Tabs";
import {TitleIcon} from "components/TitleIcon";
import {showSuccess} from "helpers/notifications.helper";
import {hasUserPermission, saveDocument} from "helpers/helper";
import {roles} from "constants/auth.const";
import {CONTRACTS, toProjectId} from "constants/routes.const";
import {SignedContractSignatories} from "components/contractProcess/SignedContractSignatories";
import {ContractSignatories} from "components/contractProcess/ContractSignatories";
import {ContractCreateInfo} from "components/contractProcess/ContractCreateInfo/ContractCreateInfo";
import {contractModalNames} from "constants/modal.conts";
import {contractBuyerDocumentTabs} from "constants/tabs.const";
import {ContractViewInfo} from "components/contractProcess/ContractViewInfo";
import {hasPermission, removeFilesConfirmModal} from "helpers/helper";
import {LockValue} from "components/LockValue";
import RequestDocuments from "components/requestCard/RequestDocuments";

export const Contract = inject("store")(
  observer(({store: {buyer, clientStore, organization: organizationStore, auth}}) => {
    const {organizationName, user, clientRole} = auth;
    const {organizationMembersSelectList} = organizationStore;
    const {language} = clientStore;
    const {
      currentOffer,
      currentRequest,
      contractOwnPersons,
      contractSellerPersons,
      proposalWithContract,
      isFinishedOwnSignatures,
      isFinishedOtherSignatures,
      isFinishedContract,
      isContract,
      currentContract,
      contractId,
    } = buyer;
    const [creatingContract, setCreatingContract] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [isEditContract, setIsEditContract] = useState(false);
    const [clearingSignature, setClearingSignature] = useState(false);

    const {t} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const backPath = useMemo(
      () =>
        location.pathname.includes("contracts")
          ? CONTRACTS
          : toProjectId(currentRequest.ProjectId),
      [location.pathname, currentRequest.ProjectId]
    );

    const canDeleteSignatory =
      contractOwnPersons?.length > 1 &&
      (hasUserPermission([roles.ADMIN, roles.OWNER], clientRole) ||
        user.id === currentRequest.ContactPersonId);

    const clickRemoveSellerDocs = (id, title) =>
      removeFilesConfirmModal(id, (id) => removeSellerDocument(id, title));

    const removeSellerDocument = (id, title) => {
      buyer.updateOffer({
        ...currentOffer,
        OfferFiles: id ? currentOffer.OfferFiles.filter((item) => item.id !== id) : [],
      });
    };

    const clickCreateContract = async () => {
      if (!hasPermission(["CREATE_BUYER_CONTRACT"])) return;
      setCreatingContract(true);
      buyer
        .createContract({
          OfferId: currentOffer.id,
          price: +currentRequest.contractPrice,
          additions: currentRequest.contractDescription,
          offerDocuments: currentOffer.OfferFiles?.map((item) => item.File.id) ?? [],
          requestDocuments: currentRequest.documents,
        })
        .then(() => {
          showSuccess();
          setIsEditContract(false);
        })
        .finally(() => setCreatingContract(false));
    };

    const clickCancelContract = () => buyer.setActiveRequestTab(2);

    const onAddSignatory = (values) =>
      buyer.addSignatoryOnContract(contractId, values.ContactPersonId);

    const onDeleteSignatory = (id) => buyer.removeSignatoryOnContract(contractId, id);

    const onSubmit = (values) => {
      auth.setSignContractSideData(contractId, "buyer", values.UserId);
      auth.updateAuthModalName(contractModalNames.identifyContractPerson);
    };

    const unlockAndCleanSignatures = async () => {
      if (!hasPermission(["DELETE_BUYER_CONTRACT"])) return;
      setClearingSignature(true);
      await buyer.removeContract(contractId);
      buyer.updateCurrentRequestInfo({
        contractPrice: currentContract?.price ?? currentOffer?.price ?? null,
        contractDescription: currentContract?.additions ?? "",
        recipients: {
          ...currentRequest.recipients,
          public: currentRequest.recipients.public.map((item) =>
            item.id === proposalWithContract?.id
              ? {...item, Offer: {...item.Offer, Contract: null}}
              : item
          ),
          registered: currentRequest.recipients.registered.map((item) =>
            item.id === proposalWithContract?.id
              ? {...item, Offer: {...item.Offer, Contract: null}}
              : item
          ),
        },
        documents: currentRequest.documents.filter(
          (item) => item.name !== "RFP_FOR_CONTRACT_REVISION_NAME"
        ),
      });
      buyer.updateOffer({...currentOffer, Contract: null});
      setClearingSignature(false);
      setIsEditContract(true);
      showSuccess(t("CANCEL_CONTRACT_INFO_MESSAGE"));
    };

    const loadPDFWatermarkContract = async (url) => {
      let fileName = `contract-${currentContract.id}.pdf`;
      const data = await buyer.downloadPdfWatermarkContract(currentContract.id, url);
      if (!data) return;
      saveDocument(data, "application/pdf", fileName, t("FILE_DOWNLOADED"));
    };

    useEffect(() => {
      contractId && buyer.getContract(contractId);
    }, [currentOffer, contractId, buyer, navigate, backPath]);

    useEffect(() => {
      if (currentContract?.id && !isEditContract && !isFinishedOtherSignatures) {
        const checkSellerSign = setInterval(() => {
          buyer.getContract(currentContract?.id);
        }, 3000);
        return () => clearInterval(checkSellerSign);
      }
    }, [buyer, isFinishedOtherSignatures, currentContract, isEditContract]);

    return (
      <div className="contract-tab_wrapper">
        {isContract && !isEditContract ? (
          <ContractViewInfo
            price={currentContract?.price?.toLocaleString(language ?? "sv")}
            currencyCode={currentRequest.CurrencyCode}
            additions={currentContract?.additions}
          />
        ) : (
          <div className="mt-12 mb-8">
            <ContractCreateInfo />
          </div>
        )}
        <div className="contract-tab_documents-tabs-title">
          <TitleIcon
            title={t("DOCUMENTS")}
            addClass="documents-title"
            icon={<IconBriefcase />}
          />
          <Tabs
            tabs={contractBuyerDocumentTabs}
            active={activeTab}
            setActive={(id) => setActiveTab(id)}
          />
        </div>
        {activeTab === 0 && (
          <RequestDocuments
            revisions={currentRequest?.documents}
            editable={!currentOffer?.Contract || isEditContract}
            columnTitles={{
              contracts: "CONTRACT",
              documents: "DOCUMENTS",
              plans: "PLANS",
              links: "LINKS",
            }}
            customLoadDocument={{
              contracts:
                isFinishedContract &&
                !currentContract?.isDocumentsReady &&
                loadPDFWatermarkContract,
            }}
            standardAgreement={currentRequest.standardAgreement}
            needZipButton
            intoContract={!currentOffer?.Contract || isEditContract}
            disableZipButton={isFinishedContract && !currentContract?.isDocumentsReady}
            allRevisionsView
            showRevisionNameInColumn={!currentOffer?.Contract || isEditContract}
          />
        )}
        {activeTab === 1 && (
          <div className="contract-tab_documents">
            <LoadDocsColumn
              title={"DOCUMENTS"}
              removable={!currentOffer?.Contract || isEditContract}
              titleIcon={<IconDocumentPen />}
              button={"ADD_DOCUMENT"}
              needZipButton={!(!currentOffer?.Contract || isEditContract)}
              docs={currentOffer?.OfferFiles ?? []}
              removeFile={clickRemoveSellerDocs}
              canRemoveAllFiles
            />
          </div>
        )}

        {!isFinishedContract && (
          <div className="contract-tab_buttons">
            {currentOffer?.Contract && !isEditContract && (
              <LockValue
                canPermissions={["DELETE_BUYER_CONTRACT"]}
                needLabel
                needShowModalToSubscribe
              >
                <ButtonTransparent
                  name={t("UNLOCK_DOCUMENTS")}
                  type="button"
                  small
                  icon={<IconLock />}
                  color="red"
                  loading={clearingSignature}
                  onClick={unlockAndCleanSignatures}
                />
              </LockValue>
            )}
            {!currentOffer?.Contract && (
              <ButtonTransparent
                name={t("CANCEL")}
                type="button"
                addClass="mr-8"
                onClick={clickCancelContract}
              />
            )}
            {(!currentOffer?.Contract || isEditContract) && (
              <LockValue
                canPermissions={["CREATE_BUYER_CONTRACT"]}
                needLabel
                needShowModalToSubscribe
              >
                <ButtonTransparent
                  name={t("LOCK_AND_PREPARE")}
                  nonTransparent
                  type="button"
                  loading={creatingContract}
                  onClick={clickCreateContract}
                />
              </LockValue>
            )}
          </div>
        )}
        {currentOffer?.Contract && !isEditContract && !isFinishedContract && (
          <ContractSignatories
            contractOwnPersons={contractOwnPersons}
            contractOtherPersons={contractSellerPersons}
            isFinishedOwnSignatures={isFinishedOwnSignatures}
            isFinishedOtherSignatures={isFinishedOtherSignatures}
            ownOrganizationName={organizationName}
            otherOrganizationName={currentOffer.Proposal?.Organization?.name}
            membersList={organizationMembersSelectList.filter(
              (member) =>
                !contractOwnPersons
                  ?.map((person) => person?.UserId)
                  .includes(member.value)
            )}
            currentUser={user}
            canDeleteMember={canDeleteSignatory}
            onSubmit={onSubmit}
            onAddSignatory={onAddSignatory}
            onDeleteSignatory={onDeleteSignatory}
            onSendMessage={() => buyer.setActiveRequestTab(4)}
          />
        )}

        {isFinishedContract && (
          <SignedContractSignatories
            contractOwnPersons={contractOwnPersons}
            contractOtherPersons={contractSellerPersons}
            ownOrganizationName={organizationName}
            otherOrganizationName={currentOffer.Proposal?.Organization?.name}
            contractId={contractId}
          />
        )}
      </div>
    );
  })
);
