import React, {useState, useCallback, useRef} from "react";
import {Upload} from "antd";
import {useTranslation} from "react-i18next";
import cn from "classnames";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import ButtonDelete from "components/buttons/ButtonDelete";
import {IconDocumentList, IconPlus, IconFolderDalux} from "components/icons";
import {beforeUploadCustom} from "helpers/uploader";
import EmptyData from "components/EmptyData";
import {CustomTooltip} from "components/CustomTooltip";
import {dateTimeFormat} from "helpers/datetime";
import {InitialsBadge} from "components/InitialsBadge";
import {extLinkDomains, extLinkIcons} from "constants/buyer.consts";
import DownloadZip from "../components/buttons/DownloadZip";
import {fileType, commonFileExtensions} from "constants/file.consts";
import {Preloader} from "../components/Preloader";

const LoadDocsColumn = (props) => {
  const {
    title,
    titleIcon,
    titleLeft,
    docPlaceholder,
    addClass,
    button,
    docs,
    docsIcon,
    editable,
    removable,
    addFile,
    removeFile,
    canRemoveAllFiles,
    loading,
    wide,
    notWide,
    halfWide,
    fullWidth,
    loadColumnTitle,
    bottomChildren,
    customAdd,
    isOpenModal,
    linksView,
    customLoadDocument,
    customFileTypes = commonFileExtensions,
    needZipButton,
    needAuthorInfo,
    currentUser,
    disableZipButton,
    allRevisionsView,
    maxFileRows,
    revisionId,
    modal,
  } = props;

  const {t} = useTranslation();

  const fileQueue = useRef([]);
  const [fileList, setFileList] = useState([]);
  const [daluxLoading, setDaluxLoading] = useState(false);

  const hasFileAuthor = useCallback((item) => !!item.OrganizationMember, []);
  const hasRevisionName = useCallback(
    (link) =>
      (allRevisionsView && link.hasOwnProperty("revisionName")) ||
      link.hasOwnProperty("folderName"),
    [allRevisionsView]
  );
  const hasDirectLink = useCallback(
    (link) =>
      linksView &&
      !link.url?.includes("daluxFile") &&
      !link.hasOwnProperty("revisionName") &&
      !link.hasOwnProperty("folderName"),
    [linksView]
  );
  const hasNoFolderDaluxFile = useCallback(
    (link) =>
      linksView && link.url?.includes("daluxFile") && link.url.split("/").length < 7,
    [linksView]
  );
  const authorTooltip = useCallback(
    (member, date) =>
      `${t("ADDED_BY")}:\n${member.User.firstName} ${member.User.lastName}\n${
        member.Organization.name
      }\n${dateTimeFormat(date, "-")}`,
    [t]
  );

  const buttonAdd = (
    <ButtonTransparent
      addClass="load-document-column__btn-add mt-2"
      icon={<IconPlus />}
      name={t(button)}
      type="button"
      small
      onClick={() => (!!customAdd ? customAdd() : true)}
      loading={(loading && loadColumnTitle === title) || daluxLoading}
      disabled={loading && loadColumnTitle !== title}
      style={{width: loading && loadColumnTitle === title && "120px"}}
    />
  );

  const isShowZipButton = needZipButton && !editable && docs?.length > 1;

  const clickLoad = (e, url) => {
    if (linksView && !url?.includes("daluxFile")) return;
    setDaluxLoading(true);
    e.preventDefault();
    !daluxLoading &&
      !!customLoadDocument &&
      customLoadDocument(url).finally(() => setDaluxLoading(false));
  };

  const rowsStyles = maxFileRows
    ? {
        maxHeight: `${maxFileRows * 20 + 10 * (maxFileRows - 1)}px`,
        overflowY: "scroll",
        overflowX: "hidden",
      }
    : {};

  const handleAdd = (e, title) => {
    const files = e.fileList;
    setFileList([]);

    const data = new FormData();
    data.append("file", e.file.originFileObj);
    !!fileType[title] && data.append("type", fileType[title]);
    fileQueue.current.push(data);

    if (fileQueue.current.length === files.length) {
      addFile(fileQueue.current, title);
      fileQueue.current = [];
      setFileList([]);
    }
  };

  return (
    <div
      className={cn(
        "load-document-column__wrapper",
        {__edit: editable},
        {"__not-wide": notWide},
        {"__half-wide": halfWide},
        {__wide: wide},
        {"__full-width": fullWidth},
        {[addClass]: !!addClass}
      )}
    >
      <h4 className={cn("load-document-column__title", {__left: titleLeft})}>
        {titleIcon}
        <span className="text-ellipsis">{t(title)}</span>
        {isShowZipButton && (
          <DownloadZip
            key={docs.reduce(
              (key, item) =>
                key.concat(linksView ? item.url : item?.File?.id ?? item?.id),
              ""
            )}
            revisionId={revisionId}
            linksView={linksView}
            docs={docs.filter((doc) => !doc.hasOwnProperty("revisionName"))}
            disabled={disableZipButton}
          />
        )}
      </h4>
      <div className={cn("load-document-column__body", {__edit: editable})}>
        {!!canRemoveAllFiles &&
          !allRevisionsView &&
          (editable || removable) &&
          docs?.length > 1 && (
            <ButtonDelete
              addClass="load-document-column__btn-delete mla mb-2"
              onClick={() => removeFile(null, title)}
              activeOnHover
            />
          )}
        {!!docs?.length && (
          <div className="load-document-column__items" style={rowsStyles}>
            {docs.map((item) => (
              <div
                key={item?.File?.id ?? item?.id}
                className={cn("load-document-column__item", {
                  "__revision-name": hasRevisionName(item),
                  "__direct-link": hasDirectLink(item),
                  "__no-folder-dalux-file": hasNoFolderDaluxFile(item),
                })}
              >
                {allRevisionsView && item.hasOwnProperty("revisionName") ? (
                  <span className="__bold __uppercase">{t(item.revisionName)}</span>
                ) : item.hasOwnProperty("folderName") ? (
                  <React.Fragment>
                    <IconFolderDalux />
                    <span>{item.folderName}</span>
                  </React.Fragment>
                ) : item.id === "DALUX_FILES_DIVIDER" ? (
                  <span className="wdth-50"></span>
                ) : (
                  <React.Fragment>
                    {docsIcon ||
                      (linksView ? (
                        extLinkIcons[
                          extLinkDomains.find((link) => item?.url?.includes(link))
                        ]
                      ) : (
                        <IconDocumentList color="#1BA2CC" />
                      ))}
                    <a
                      href={item?.url || item?.File?.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="load-document-column__link"
                      disabled={daluxLoading}
                      onClick={(e) =>
                        customLoadDocument
                          ? clickLoad(e, item?.url || item?.File?.url)
                          : null
                      }
                    >
                      {item?.name || item?.File?.name}
                    </a>
                    {!editable && needAuthorInfo && hasFileAuthor(item) && (
                      <CustomTooltip
                        text={authorTooltip(item.OrganizationMember, item.updatedAt)}
                        addClass="ml-2"
                      >
                        <span>
                          <InitialsBadge
                            firstName={item.OrganizationMember.User.firstName}
                            lastName={item.OrganizationMember.User.lastName}
                            isCurrentUser={
                              item.OrganizationMember.User.id === currentUser?.id
                            }
                          />
                        </span>
                      </CustomTooltip>
                    )}
                    {(editable || removable) && (
                      <ButtonDelete
                        addClass="load-document-column__btn-delete"
                        onClick={() => removeFile(item?.id, title)}
                        activeOnHover
                      />
                    )}
                  </React.Fragment>
                )}
              </div>
            ))}
          </div>
        )}

        {editable && <span className="placeholder">{t(docPlaceholder)}</span>}

        {!editable && !docs?.length && <EmptyData text={t("NO_FILES_IN_LIST")} />}

        {/* shown while daluxLoading is true */}
        {daluxLoading && (
          <div className="streaming-external-file dalux">
            <Preloader size={200} />
            <p className="heading">{t("DALUX_LOADING_SPINNER_HEADER")}</p>
            <p>{t("DALUX_LOADING_SPINNER_BODY")}</p>
          </div>
        )}

        {editable && !customAdd && (
          <Upload
            className="load-document-column__upload"
            customRequest={() => {}}
            beforeUpload={(file) => beforeUploadCustom(file, customFileTypes, 76800, 0)}
            multiple
            fileList={fileList}
            onChange={(e) => handleAdd(e, title)}
            showUploadList={false}
          >
            {buttonAdd}
          </Upload>
        )}
        {editable && customAdd && buttonAdd}
        {isOpenModal && modal}
        {bottomChildren}
      </div>
    </div>
  );
};

export default LoadDocsColumn;
