import React, {useState, useEffect, useCallback} from "react";
import {inject, observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {Form, Formik} from "formik";
//local
import SubHeading from "components/SubHeading";
import {Preloader} from "components/Preloader";
import CustomRadioSwitch from "components/CustomRadioSwitch";
import MiniSelect from "components/selects/MiniSelect";
import {hasUserPermission, hasPermission} from "helpers/helper";
import {showError, showSuccess} from "helpers/notifications.helper";
import {roles} from "constants/auth.const";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {DepartmentLabelModal} from "components/modals/DepartmentLabelModal";
import {QualificationQuestionsModal} from "./components/QualificationQuestionsModal";
import {MonitorSuppliersFilterModal} from "./components/MonitorSuppliersFilterModal";
import {QualificationMessageModal} from "./components/QualificationMessageModal";
import {SmartTemplatesModal} from "components/modals/SmartTemplatesModal";
import {ConfirmModal} from "components/ConfirmModal";
import {Tag} from "components/Tag";
import {
  IconContractNumber,
  IconCurrency,
  IconLabel,
  IconPlus,
  IconCog,
  IconDocumentDalux,
  IconBullHorn,
  IconCheckMark,
  IconPaperPencil,
  IconList,
  IconBinoculars,
} from "components/icons";
import SimpleField from "components/SimpleField";
import {LockValue} from "components/LockValue";
import DaluxBanner from "assets/images/svg/dalux-banner.svg";
import {InfoTooltip} from "components/InfoTooltip";
import {color} from "constants/color.consts";

export const OrganizationSettings = inject("store")(
  observer(({store: {organization, auth, clientStore, nsi}}) => {
    const {t} = useTranslation();
    const {clientRole, user, organizationName, activeOrganization} = auth;
    const {departments} = organization;
    const [loadingSave, setLoadingSave] = useState(false);
    const [settings, setSettings] = useState(null);
    const [currencies, setCurrencies] = useState([]);
    const [qualificationQuestions, setQualificationQuestions] = useState([]);
    const [isDepartmentLabel, setIsDepartmentLabel] = useState(null);
    const [isQualificationQuestionsModal, setQualificationQuestionsModal] =
      useState(false);
    const [isMonitorSuppliersFilterModal, setMonitorSuppliersFilterModal] =
      useState(false);
    const [isSmartTemplatesModal, setSmartTemplatesModal] = useState(false);
    const [isQualificationMessageModal, setQualificationMessageModal] = useState(false);
    const [loadingChangeDepartmentLabel, setLoadingChangeDepartmentLabel] =
      useState(false);
    const isAdminOwner = hasUserPermission([roles.ADMIN, roles.OWNER], clientRole);
    const isOwner = hasUserPermission([roles.OWNER], clientRole);
    const isDaluxEnable =
      settings?.enableDaluxIntegration ?? activeOrganization?.enableDaluxIntegration;
    const isMonitoringSuppliersEnabled = activeOrganization?.allowMonitorSuppliers;
    const isEnabledSupplierQualification = settings?.enableSuppliersQualification;

    const getQualificationQuestions = useCallback(
      () =>
        clientStore.getQualificationQuestions().then(({data}) =>
          setQualificationQuestions(
            data.suppliersQualificationQuestions
              ? data.suppliersQualificationQuestions.map((question) => ({
                  ...question,
                  files: question.files.map((file) => ({...file, ...file.File})),
                }))
              : []
          )
        ),
      [clientStore]
    );

    const saveQualificationMessage = (suppliersQualificationMessage) =>
      handleUpdate({suppliersQualificationMessage}).then(() =>
        setQualificationMessageModal(false)
      );

    const handleUpdate = (
      values,
      canUpdate = isAdminOwner,
      errorTextKey = "WRONG_PRIVILEGES_ONLY_OWNER_ADMIN"
    ) =>
      canUpdate
        ? organization.updateOrganizationSettings(values).then(setSettings)
        : showError(errorTextKey);

    const handleSaveDepartmentLabel = async (name) => {
      try {
        setLoadingChangeDepartmentLabel(true);
        isDepartmentLabel?.id
          ? await organization.updateOrganizationDepartmentLabel(isDepartmentLabel.id, {
              name,
            })
          : await organization.createOrganizationDepartmentLabel({name});
        setIsDepartmentLabel(null);
        showSuccess(
          isDepartmentLabel?.id ? t("SUCCESSFULLY_UPDATED") : t("SUCCESSFULLY_CREATED")
        );
        organization.getOrganizationDepartmentLabels();
      } finally {
        setLoadingChangeDepartmentLabel(false);
      }
    };

    const handleRemoveDepartmentLabel = () =>
      ConfirmModal({
        title: t("WANT_DELETE_DEPARTMENT_LABEL"),
        text: t("CANT_DELETE_DEPARTMENT_LABEL"),
        type: "warning",
        onOk: () => {
          setLoadingChangeDepartmentLabel(true);
          organization
            .deleteOrganizationDepartmentLabel(isDepartmentLabel.id)
            .then(() => {
              setIsDepartmentLabel(null);
              showSuccess(t("SUCCESSFULLY_REMOVED"));
              organization.getOrganizationDepartmentLabels();
            })
            .finally(() => setLoadingChangeDepartmentLabel(false));
        },
      });

    const onSubmitDaluxSettings = (values) => {
      if (!isAdminOwner) return;
      setLoadingSave(true);
      handleUpdate(values).finally(() => setLoadingSave(false));
    };

    useEffect(() => {
      organization.getOrganizationSettings().then(setSettings);
      organization.getOrganizationDepartmentLabels();
      getQualificationQuestions();
      nsi.getCurrencies().then(setCurrencies);
    }, [organization, nsi, getQualificationQuestions]);

    return settings && currencies ? (
      <div className="organization-settings__wrapper">
        <h3 className="title">{t("ORGANISATION_SETTINGS_TITLE")}</h3>
        <p className="info">{t("ORGANISATION_SETTINGS_INFO_TEXT")}</p>
        <div className="organization-settings__columns">
          <div className="organization-settings__left-column">
            <div className="organization-settings__contract-number mb-12">
              <SubHeading
                title="SETTINGS_ORGANIZATION_CONTRACT_NUMBERS"
                icon={<IconContractNumber />}
              />
              <CustomRadioSwitch
                checked={settings?.showContractNumber}
                mediumText
                label={t("SETTINGS_ORGANIZATION_CONTRACT_NUMBERS_SWITCH_LABEL")}
                small
                addWrapperClass="mt-4"
                informationText="SETTINGS_ORGANIZATION_CONTRACT_NUMBERS_SWITCH_TOOLTIP"
                isActive={(checked) => handleUpdate({showContractNumber: checked})}
              />
            </div>
            <div className="organization-settings__default-currency mb-12">
              <SubHeading title="DEFAULT_CURRENCY" icon={<IconCurrency />} />
              <MiniSelect
                list={currencies ?? []}
                defaultValue={settings.defaultCurrencyCode ?? "SEK"}
                addClass="mt-4"
                fullWidthDropdownLabel
                onChange={(value) => handleUpdate({defaultCurrencyCode: value})}
                label={t("SETTINGS_ORGANIZATION_CURRENCY_LABEL")}
                informationText="SETTINGS_ORGANIZATION_CURRENCY_TOOLTIP"
              />
            </div>
            <div className="organization-settings__department-labels mb-12">
              <SubHeading
                title="SETTINGS_ORGANIZATION_PUBLIC_RFP_TITLE"
                icon={<IconBullHorn />}
                hasNew
              />
              <div className="mt-4">
                {t("SETTINGS_ORGANIZATION_PUBLIC_RFP_DESCRIPTION")}
              </div>
              <LockValue
                canPermissions={["ENABLE_SWITCH_OFF_CREATE_OPEN_RFT"]}
                needLabel
                addClassIcon="mt-4"
                needShowModalToSubscribe
              >
                <CustomRadioSwitch
                  checked={settings?.allowPublicRft}
                  mediumText
                  label={t("SETTINGS_ORGANIZATION_PUBLIC_RFP_SWITCH_02_LABEL")}
                  small
                  addWrapperClass="mt-4"
                  informationText="SETTINGS_ORGANIZATION_PUBLIC_RFP_SWITCH_02_TOOLTIP"
                  isActive={(checked) =>
                    hasPermission(
                      ["ENABLE_SWITCH_OFF_CREATE_OPEN_RFT"],
                      user.permissions
                    ) && handleUpdate({allowPublicRft: checked})
                  }
                />
              </LockValue>

              <LockValue
                canPermissions={["ENABLE_SWITCH_OFF_CREATE_OPEN_RFT"]}
                needLabel
                addClassIcon="mt-4"
                needShowModalToSubscribe
              >
                <CustomRadioSwitch
                  checked={settings?.publicRrfDefault}
                  mediumText
                  label={t("SETTINGS_ORGANIZATION_PUBLIC_RFP_SWITCH_03_LABEL")}
                  small
                  addWrapperClass="mt-4"
                  informationText="SETTINGS_ORGANIZATION_PUBLIC_RFP_SWITCH_03_TOOLTIP"
                  isActive={(checked) =>
                    hasPermission(
                      ["ENABLE_SWITCH_OFF_CREATE_OPEN_RFT"],
                      user.permissions
                    ) && handleUpdate({publicRrfDefault: checked})
                  }
                  disabled={
                    !settings?.allowPublicRft ||
                    !hasPermission(
                      ["ENABLE_SWITCH_OFF_CREATE_OPEN_RFT"],
                      user.permissions
                    )
                  }
                />
              </LockValue>
            </div>
            <div className="supplier-qualification mb-12">
              <SubHeading
                title="SETTINGS_ORGANIZATION_OUR_SUPPLIERS"
                icon={<IconCheckMark />}
                videoLink={{
                  label: `VIDEO_LINK_SUPPLIER_QUALIFICATION_LABEL_2`,
                  link: `VIDEO_LINK_SUPPLIER_QUALIFICATION_LINK`,
                }}
              />
              <div className="df-row-center mt-4">
                <CustomRadioSwitch
                  checked={settings?.enableSuppliersQualification}
                  mediumText
                  label={t("SETTINGS_ORGANIZATION_OUR_SUPPLIERS_SWITCH_LABEL")}
                  small
                  informationText="SETTINGS_ORGANIZATION_OUR_SUPPLIERS_SWITCH_TOOLTIP"
                  isActive={(checked) =>
                    handleUpdate(
                      {enableSuppliersQualification: checked},
                      isOwner,
                      "WRONG_PRIVILEGES_ONLY_OWNER"
                    )
                  }
                />
              </div>
              <div className="df-row-center mt-2">
                <ButtonTransparent
                  name="SETTINGS_ORGANIZATION_OUR_SUPPLIERS_QUESTIONS_BUTTON"
                  icon={<IconCog />}
                  noBorder
                  small
                  addClass="fz-14 pl-0 __rotate-on-hover"
                  onClick={() => setQualificationQuestionsModal(true)}
                />
                <ButtonTransparent
                  name="SETTINGS_ORGANIZATION_OUR_SUPPLIERS_MESSAGE_BUTTON"
                  icon={<IconPaperPencil color={color.blue} />}
                  noBorder
                  small
                  addClass="fz-14 ml-2"
                  onClick={() => setQualificationMessageModal(true)}
                />
              </div>
              {isEnabledSupplierQualification && (
                <div className="sub-heading mt-4 df-column-start">
                  <div className="df-row-center mb-2">
                    <h4 className="ml-0 df-row-center mr-2">
                      <span className="mr-2">
                        {t(
                          "SETTINGS_ORGANIZATION_OUR_SUPPLIERS_CONTACROVERRIDES_HEADING"
                        )}
                      </span>
                      <InfoTooltip
                        tooltip="SETTINGS_ORGANIZATION_OUR_SUPPLIERS_CONTACROVERRIDES_TOOLTIP"
                        centerText
                        addIconWrapperClass="field-tooltip"
                      />
                    </h4>
                  </div>
                  <span className="mb-1" style={{fontSize: "12px", fontStyle: "italic"}}>
                    {t(
                      "SETTINGS_ORGANIZATION_OUR_SUPPLIERS_CONTACROVERRIDES_EXTRA_HEADING_PT_1"
                    )}{" "}
                    <a href={`${t("MAIL_TO_SUPPORT_LINK")}`}>{t("MAIL_TO_SUPPORT")}</a>{" "}
                    {t(
                      "SETTINGS_ORGANIZATION_OUR_SUPPLIERS_CONTACROVERRIDES_EXTRA_HEADING_PT_2"
                    )}
                  </span>
                  <div className="members-to-notify webkit-msedge-chrome-nicer-scrollbar vertical-scroll df-column-start">
                    {settings?.buyerOrgMembersToNotify?.length > 0 ? (
                      settings.buyerOrgMembersToNotify.map((member) => (
                        <Tag
                          key={member.id}
                          text={`${member.firstName} ${member.lastName} - ${member.email}`}
                          addClass="mb-1"
                        />
                      ))
                    ) : (
                      <span style={{fontSize: "12px", fontStyle: "italic"}}>
                        {t("SETTINGS_ORGANIZATION_OUR_SUPPLIERS_CONTACROVERRIDES_EMPTY")}
                      </span>
                    )}
                  </div>
                </div>
              )}
            </div>
            {hasPermission(["ENABLE_MONITOR_SUPPLIERS"], user.permissions) && (
              <div className="mb-12">
                <div className="df">
                  <SubHeading
                    title="SETTINGS_ORGANIZATION_MONITOR_SUPPLIERS"
                    icon={<IconBinoculars height="20" width="21" />}
                  />
                </div>
                <div className="df-row-center mt-4">
                  {isMonitoringSuppliersEnabled
                    ? t("SETTINGS_ORGANIZATION_MONITOR_SUPPLIERS_STAT")?.format(
                        settings.monitorSuppliersCompaniesCount ?? 0,
                        settings.monitorSuppliersUsersCount ?? 0
                      )
                    : t("SETTINGS_ORGANIZATION_MONITOR_SUPPLIERS_DESCRIPTION")}
                </div>
                {isMonitoringSuppliersEnabled ? (
                  <ButtonTransparent
                    name="SETTINGS_ORGANIZATION_MONITOR_SUPPLIERS_SETTINGS_BUTTON"
                    icon={<IconCog />}
                    noBorder
                    small
                    addClass="fz-14 pl-0 __rotate-on-hover mt-3"
                    onClick={() => setMonitorSuppliersFilterModal(true)}
                  />
                ) : (
                  <a
                    className="link df mt-4"
                    href={t("URL_TO_PUBLIC_CONTACT_PAGE")}
                    target="_new"
                  >
                    {t("SETTINGS_ORGANIZATION_MONITOR_SUPPLIERS_LINK")}
                  </a>
                )}
              </div>
            )}
          </div>
          <div className="organization-settings__right-column">
            <div className="organization-settings__department-labels mb-12">
              <SubHeading title="DEPARTMENTS" icon={<IconLabel />} />
              <CustomRadioSwitch
                checked={settings?.useDepartmentsForProjects}
                mediumText
                label={t("DEPARTMENTS_SWITCH_LABEL")}
                small
                addWrapperClass="mt-4"
                informationText="DEPARTMENTS_SWITCH_TOOLTIP"
                isActive={(checked) => handleUpdate({useDepartmentsForProjects: checked})}
              />
              <div className="df-row-center mt-4">
                {!!departments?.length &&
                  departments.map((department) => (
                    <ButtonTransparent
                      addClass="organization-settings__department-labels__department-button __rotate-on-hover"
                      key={department.id}
                      name={department.name}
                      small
                      rounded
                      uppercase
                      color="black"
                      iconRight={<IconCog />}
                      onIconClick={() =>
                        isOwner
                          ? setIsDepartmentLabel(department)
                          : showError("WRONG_PRIVILEGES_ONLY_OWNER")
                      }
                    />
                  ))}
                <ButtonTransparent
                  addClass="organization-settings__department-labels__add-button"
                  name="SETTINGS_ORGANIZATION_DEPARTMENTS_ADD_BUTTON"
                  small
                  rounded
                  color="black"
                  icon={<IconPlus />}
                  onClick={() =>
                    isOwner
                      ? setIsDepartmentLabel({})
                      : showError("WRONG_PRIVILEGES_ONLY_OWNER")
                  }
                />
              </div>
            </div>
            <div className="organization-settings mb-12">
              <SubHeading
                title="SETTINGS_ORGANIZATION_SMART_TEMPLATES"
                icon={<IconList />}
              />
              <div className="df-row-center mt-2">
                <ButtonTransparent
                  name="SETTINGS_ORGANIZATION_SMART_TEMPLATES_BUTTON"
                  icon={<IconCog />}
                  noBorder
                  small
                  addClass="fz-14 pl-0 __rotate-on-hover"
                  onClick={() => setSmartTemplatesModal(true)}
                />
              </div>
            </div>

            <div className="organization-settings__dalux-settings">
              <SubHeading title="SETTINGS_DALUX_TITLE" icon={<IconDocumentDalux />} />
              {isDaluxEnable ? (
                <React.Fragment>
                  <CustomRadioSwitch
                    checked={settings?.allowDalux}
                    mediumText
                    label={t("SETTINGS_DALUX_SWITCH_LABEL")}
                    small
                    addWrapperClass="mt-4"
                    informationText="SETTINGS_DALUX_SWITCH_TOOLTIP"
                    isActive={(checked) => handleUpdate({allowDalux: checked})}
                  />
                  <Formik
                    initialValues={{
                      daluxBaseURL: isOwner
                        ? settings?.daluxBaseURL
                        : "********************",
                      daluxApiKey: isOwner
                        ? settings?.daluxApiKey
                        : "********************",
                    }}
                    enableReinitialize
                    onSubmit={onSubmitDaluxSettings}
                  >
                    {({values, dirty, setFieldValue, isValid}) => (
                      <Form>
                        <div className="df-row-end">
                          <SimpleField
                            name="daluxBaseURL"
                            placeholder={t("SETTINGS_DALUX_BASE_URL_PLACEHOLDER")}
                            label={t("SETTINGS_DALUX_BASE_URL_NAME")}
                            small
                            disabled={!isOwner}
                            className="input mr-2"
                          />
                          <InfoTooltip
                            tooltip="SETTINGS_DALUX_BASE_URL_TOOLTIP"
                            centerText
                            addIconWrapperClass="field-tooltip"
                          />
                        </div>
                        <div className="df-row-end">
                          <SimpleField
                            name="daluxApiKey"
                            placeholder={t("SETTINGS_DALUX_API_KEY_PLACEHOLDER")}
                            label={t("SETTINGS_DALUX_API_KEY_NAME")}
                            small
                            disabled={!isOwner}
                            className="input mr-2"
                          />
                          <InfoTooltip
                            tooltip="SETTINGS_DALUX_API_KEY_TOOLTIP"
                            centerText
                            addIconWrapperClass="field-tooltip"
                          />
                        </div>

                        {isOwner && (
                          <ButtonTransparent
                            type="submit"
                            loading={loadingSave}
                            disabled={
                              !(
                                values.daluxBaseURL?.length || values.daluxApiKey?.length
                              ) || !dirty
                            }
                            name={"SETTINGS_DALUX_SAVE_BUTTON"}
                            small
                            nonTransparent
                            addClass="organization-settings__dalux-settings__save-button"
                          />
                        )}
                      </Form>
                    )}
                  </Formik>
                </React.Fragment>
              ) : (
                <div style={{display: "flex", flexDirection: "column", width: "250px"}}>
                  <img
                    src={DaluxBanner}
                    className=""
                    alt=""
                    style={{padding: "20px 0 10px 0"}}
                  />

                  <a
                    className="link"
                    href={t("URL_TO_PUBLIC_CONTACT_PAGE")}
                    target="_new"
                  >
                    {t("DALUX_SETTINGS_TEASER")}
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>

        {isDepartmentLabel && (
          <DepartmentLabelModal
            department={isDepartmentLabel}
            onSave={handleSaveDepartmentLabel}
            onRemove={handleRemoveDepartmentLabel}
            onClose={() => setIsDepartmentLabel(null)}
            loading={loadingChangeDepartmentLabel}
          />
        )}
        {isQualificationQuestionsModal && (
          <QualificationQuestionsModal
            initialQuestions={qualificationQuestions}
            isOwner={isOwner}
            onClose={({needUpdateQuestions}) => {
              needUpdateQuestions && getQualificationQuestions();
              setQualificationQuestionsModal(false);
            }}
          />
        )}
        {isMonitorSuppliersFilterModal && (
          <MonitorSuppliersFilterModal
            initialFilters={settings.monitorSuppliersFilter}
            isAdminOwner={isAdminOwner}
            onChange={(monitorSuppliersFilter) => handleUpdate({monitorSuppliersFilter})}
            onClose={() => setMonitorSuppliersFilterModal(false)}
          />
        )}
        {isQualificationMessageModal && (
          <QualificationMessageModal
            initialMessage={settings.suppliersQualificationMessage}
            organizationName={organizationName}
            onSave={saveQualificationMessage}
            onClose={() => setQualificationMessageModal(false)}
          />
        )}
        {isSmartTemplatesModal && (
          <SmartTemplatesModal
            defaultTab={1}
            disabledTabs={[true, false]}
            isAdminOwner={isAdminOwner}
            onClose={() => setSmartTemplatesModal(false)}
          />
        )}
      </div>
    ) : (
      <Preloader addClass="preloader250" />
    );
  })
);
