import React, {memo} from "react";

export const IconBookmarkFilled = memo(({color, width, height, addClass}) => {
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 19.5C15.857 19.5 15.717 19.438 15.62 19.325L10 12.768L4.38 19.325C4.244 19.484 4.023 19.541 3.827 19.469C3.631 19.397 3.5 19.209 3.5 19V1C3.5 0.724 3.724 0.5 4 0.5H16C16.276 0.5 16.5 0.724 16.5 1V19C16.5 19.209 16.37 19.396 16.173 19.469C16.116 19.49 16.058 19.5 16 19.5Z"
        fill={color || "#000"}
      />
      <rect
        x="12.5"
        y="6"
        width="1"
        height="5"
        rx="0.5"
        transform="rotate(90 12.5 6)"
        fill="white"
      />
    </svg>
  );
});
