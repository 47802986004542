import React, {useState, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {usePostHog} from "posthog-js/react";
import {Link} from "react-router-dom";
import {inject, observer} from "mobx-react";
//locale
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import SingleRequestInterestStatus from "./SingleRequestInterestStatus";
import HandyPointerBlob from "components/HandyPointerBlob";
import {dateFormat} from "helpers/datetime";
import {ContractNumber} from "components/contractProcess/ContractNumber";
import GuidedTimeline from "components/GuidedTimeline";
import {Tag} from "components/Tag";
import {dropdownStatusColors} from "constants/select.consts";
import Select from "components/selects/Select";
import {saveDocument} from "helpers/helper";
import {ProjectLabel} from "components/ProjectLabel";
import RequestContacts from "../../components/componentsRequestTab/RequestContacts";
import {InfoTooltip} from "components/InfoTooltip";
import DownloadCalendarFile from "components/DownloadCalendarLink";
import {ShareButton} from "components/buttons/ShareButton";
import {copyToClipboard} from "helpers/helper";
import {LockValue} from "components/LockValue";
import {hasPermission} from "helpers/helper";
import {ButtonBookmarkByMembers} from "components/buttons/ButtonBookmarkByMembers";
import {
  IconPrint,
  IconUserPlus,
  IconChevronDown,
  IconLock,
  IconUnlock,
  IconMapMarker,
  IconHourglass,
} from "components/icons";
import {toOrganizationId} from "constants/routes.const";
import {procurementStatuses} from "constants/buyer.consts";
import {color} from "constants/color.consts";
import cn from "classnames";

const SingleRequestHead = inject("store")(
  observer(({store: {auth, organization: organizationStore, seller, clientStore}}) => {
    const {request, activeRequestTab, isFinishedContract, isContract, currentContract} =
      seller;
    const {organizationMembersSelectList} = organizationStore;
    const {user} = auth;
    const [isDownload, setIsDownload] = useState(false);
    const {t} = useTranslation();
    const posthog = usePostHog();

    const isPassedDate = request?.Request?.closedAt
      ? new Date().getTime() > new Date(request?.Request?.closedAt).getTime()
      : true;
    const procurementStatus = request?.Request?.ProjectItem?.status;
    const isStoppedProcurement = procurementStatus === procurementStatuses.STOPPED;
    const isClosedForOffers = request?.Request?.hardDateCloseOffers;
    const rfpProject = request?.Request?.ProjectItem?.Project;
    const isPublic = request?.Request?.isPublic;
    const hasProposal = !!request?.id;

    const isContractTab = activeRequestTab === 2;
    const isShowContractNumber = user?.ActiveOrganization?.showContractNumber;
    const isShowStatuses =
      !isFinishedContract &&
      !isContractTab &&
      !(isClosedForOffers && isPassedDate) &&
      ((isPublic && !!request.Offer?.id) || !isStoppedProcurement);

    const downloadPDF = async () => {
      setIsDownload(true);
      const fileName = `contract-${currentContract.id}`;
      const data = await clientStore.downloadPDF(currentContract.id, "seller");
      saveDocument(data, "application/pdf", fileName);
      setIsDownload(false);
    };

    const hasOffer = useMemo(() => !!request.Offer?.id, [request.Offer?.id]);

    // Set the time of calendarStartDate to 5 PM (17:00 for downloaded calendar files)
    const calendarStartDate = useMemo(
      () => new Date(new Date(request?.Request?.closedAt).setHours(17, 0, 0, 0)),
      [request?.Request?.closedAt]
    );
    // Adding 60 minutes as end date for calendar files
    const calendarEndDate = useMemo(
      () => new Date(calendarStartDate.getTime() + 3600000),
      [calendarStartDate]
    );

    const truncateIcalDescription = (text, maxLength = 200) => {
      if (!text) return ""; // Handle empty text

      // Strip calendar description HTML tags using regular expression
      const strippedText = text.replace(/<[^>]*>/g, "");

      // Truncate calendar description text if it exceeds maxLength
      return strippedText.length > maxLength
        ? strippedText.slice(0, maxLength - 3) + "..."
        : strippedText;
    };

    const copyLink = () => {
      posthog?.capture("Clicked share button on single RFT");
      copyToClipboard(window.location, true);
    };

    const handleClickBookmarkButton = (isAdding) => {
      const id = hasProposal ? request?.id : request?.Request?.id;
      isAdding
        ? hasProposal
          ? seller.setBookmarkRequest(id)
          : seller.setBookmarkPublicRfp(id)
        : hasProposal
        ? seller.deleteBookmarkRequest(id)
        : seller.deleteBookmarkPublicRfp(id);
    };

    return (
      <div className="request-to-us_header__wrapper hd-content-block_header __wrapper">
        <div className="hd-content-block_header __inner-wrapper title-date">
          <div className="hd-content-block_header __left-block">
            <h3 className="hd-content-block_header __title df-row-center">
              {request?.Request?.name}
            </h3>
            {isPublic && (
              <ButtonBookmarkByMembers
                bookmarks={request?.Request?.RequestBookmarkedByMembers}
                currentUser={user}
                tooltipEmpty="PUBLIC_RFT_PAGE_BOOKMARK_TOOLTIP"
                tooltipFilled="PUBLIC_RFT_PAGE_BOOKMARK_FILLED_TOOLTIP"
                onClick={handleClickBookmarkButton}
                addClass="ml-4"
              />
            )}

            {isContract && !isFinishedContract && (
              <span className="contract-status">
                <IconHourglass color="black" /> {t("CONTRACT_FOR_SIGNATURE")}
              </span>
            )}
            {isStoppedProcurement && (
              <Tag
                text={procurementStatus}
                color={dropdownStatusColors[procurementStatus]}
                needTranslation
                addClass="ml-4 mb-1 fz-10"
                bold
                uppercase
              />
            )}
            {isStoppedProcurement && (
              <InfoTooltip
                tooltip="REQUEST_TO_US_STOPPED_PROCUREMENT_TOOLTIP"
                centerText
              />
            )}
          </div>

          {!isContractTab && (
            <div className="hd-content-block_header __right-block">
              <div
                className={`hd-content-block_header __date ${
                  isStoppedProcurement ? "stopped" : ""
                } ${isContract ? "finished-contract" : ""}`}
              >
                <span className="open-closed df-row-center">
                  {isClosedForOffers ? (
                    isPassedDate ? (
                      <IconLock width="15" height="15" addClass="mr-1" />
                    ) : (
                      <IconUnlock width="15" height="15" addClass="mr-1" />
                    )
                  ) : null}
                  <span
                    className={cn({"__closed-offer": isClosedForOffers && isPassedDate})}
                  >
                    {isClosedForOffers
                      ? isPassedDate
                        ? t("CLOSED_FOR_OFFERS_HARD")
                        : t("CLOSES_FOR_OFFERS_HARD")
                      : t("CLOSES_SOFT")}
                  </span>
                </span>
                {dateFormat(request?.Request?.closedAt)}
                {hasOffer && !isStoppedProcurement && (
                  <div className="df-row-center statuses">
                    <GuidedTimeline
                      offerSubmitted={!!request?.Offer?.id}
                      offerRead={request?.Offer?.readed}
                      evaluatingOffer={request?.Offer?.evaluate === true}
                      offerDeclined={request?.Offer?.evaluate === false}
                      isContract={isContract}
                      isFinishedContract={isFinishedContract}
                      addClass="mt-4"
                    />
                  </div>
                )}
              </div>

              {/* Show calendar button if we have not made an offer and the RFT is not stopped */}
              {!hasOffer && !isStoppedProcurement && (
                <div className="add-rft-to-calendar">
                  <DownloadCalendarFile
                    summary={`${request?.Request?.Buyer?.name}・${request?.Request?.name} [${rfpProject?.name}]`}
                    description={truncateIcalDescription(request?.Request?.description)}
                    location={`${window.location.origin}/requests-to-us`}
                    start={calendarStartDate}
                    end={calendarEndDate}
                    addClass="add-rft-to-calendar"
                  />
                </div>
              )}
              {/* Show share button if this RFT is public and not stopped and we have not made an offer */}
              {!isStoppedProcurement && isPublic && !hasOffer && (
                <div className="share-feature">
                  <ShareButton
                    addClass="feed-post-view__share-button"
                    name="SHARE_THIS"
                    onClickCopy={copyLink}
                  />
                </div>
              )}
            </div>
          )}
          {isContractTab && (
            <div className="hd-content-block_header __right-block">
              <div>
                <GuidedTimeline
                  offerSubmitted={!!request?.Offer?.id}
                  offerRead={request?.Offer?.readed}
                  evaluatingOffer={request?.Offer?.evaluate === true}
                  offerDeclined={request?.Offer?.evaluate === false}
                  isContract={isContract}
                  isFinishedContract={isFinishedContract}
                  addClass="mb-4"
                />
                {isFinishedContract && (
                  <div className="view-header__button-row df-row-wrap-jce-end">
                    {/* Show contract number if contract number feature is active */}
                    {isShowContractNumber && (
                      <ContractNumber number={currentContract?.number} addClass="ml-4" />
                    )}
                    {/* Show verification download button if it's a finished contract */}
                    <ButtonTransparent
                      addClass="view-header__button-row__button ml-4"
                      name={t("PRINT")}
                      small
                      icon={<IconPrint />}
                      loading={isDownload}
                      onClick={downloadPDF}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <div className="hd-content-block_header __inner-wrapper mb-0 project-etc">
          <div className="hd-content-block_header __left-block">
            <div className="hd-content-block_header __subtitle">{`${t("PROJECT")}:`}</div>
            <div className="project-and-location mb-4">
              {rfpProject && (
                <ProjectLabel name={rfpProject.name} wideName addClass="mr-5" />
              )}
              {!!request?.Request?.location && (
                <div className="location df">
                  <IconMapMarker addClass="mr-1" color={color.blue} />
                  <span className="blue">{request?.Request?.location}</span>
                </div>
              )}
            </div>
          </div>

          <div className="hd-content-block_header __right-block">
            {/* Show if the buyer has read it. Show buyer response */}
            {isShowStatuses && <SingleRequestInterestStatus />}
          </div>
        </div>

        <div className="hd-content-block_header __inner-wrapper">
          <div className="hd-content-block_header __left-block">
            <div className="hd-content-block_header __subtitle">{`${t(
              "REQUEST_TO_US_SUBTITLE_BUYER"
            )}:`}</div>
            <Link
              to={toOrganizationId(request?.Request?.Buyer?.id)}
              end="true"
              className="__bold link"
            >
              {request?.Request?.Buyer?.name}
            </Link>
            <RequestContacts request={request} />
          </div>

          {/* Show contact person dropdown if: 
          - its not a contract
          - not closed for offers
          - not passed date
          - not stopped
          - for open RFTs, we have pressed thumbs up */}
          <div className="hd-content-block_header __right-block">
            {!isFinishedContract &&
              !isContractTab &&
              !(isClosedForOffers && isPassedDate) &&
              !isStoppedProcurement &&
              (request?.id ? (
                <div className="contact-person">
                  <InfoTooltip
                    tooltip="RFP_CONTACT_PERSON_TOOLTIP"
                    centerText
                    addClass="mr-2"
                  />
                  <LockValue
                    canPermissions={["READ_SELLER_RFTS"]}
                    needLabel
                    needShowModalToSubscribe
                    addClassIcon="mb-1 ml-1"
                  >
                    <Select
                      addClass="hd-content-block_header __assignee-select"
                      prefixIcon={<IconUserPlus />}
                      onChange={(UserAssigneeId) =>
                        seller.updateRequest({UserAssigneeId})
                      }
                      list={organizationMembersSelectList}
                      shadow
                      showArrow
                      huge
                      initials
                      highlightIfEmpty={!request?.UserAssigneeId}
                      suffixIcon={<IconChevronDown />}
                      placeholder="USER_ASSIGNEE_SELECT_INITIAL_VALUE"
                      ownValue={user.id}
                      disabled={!request?.id || !hasPermission(["READ_SELLER_RFTS"])}
                      value={request?.UserAssigneeId}
                    />
                  </LockValue>
                </div>
              ) : (
                // Show graphic explainer instead of contact person dropdown if:
                // - it's an open rft
                // - we have not pressed thumbs up
                <HandyPointerBlob
                  forcePosition="relative"
                  rightPos="30"
                  fontSize="20px"
                  showPointer
                  pointerAfterText
                  pointerDirection="up"
                  pointyAnimation
                  text="OPEN_RFT_EXPLAINER"
                />
              ))}
          </div>
        </div>
      </div>
    );
  })
);

export default SingleRequestHead;
