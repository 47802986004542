import React, {useEffect, useState, useMemo, useCallback} from "react";
import {inject, observer} from "mobx-react";
import {useTranslation} from "react-i18next";
//local
import RecipientsHead from "components/requestCard/RecipientsHead";
import CustomRadioSwitch from "components/CustomRadioSwitch";
import RecipientItem from "components/requestCard/RecipientItem";
import {AddableFormikField} from "components/AddableFormikField";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {
  IconHandOnRight,
  IconSearch,
  IconMailLetter,
  IconPlus,
  IconFunnel,
  IconCheckMark,
  IconCross,
} from "components/icons";
import {defaultNameFieldSearch} from "constants/user.consts";
import {showError, showSuccess} from "helpers/notifications.helper";
import MultipleSelect from "components/selects/MultipleSelect";
import SelectionRow from "components/selects/SelectionRow";
import {copyToClipboard} from "helpers/helper";
import {Explanation} from "components/Explanation";
import {RecipientsEditSelect} from "../components/RequestEdit/RecipientsEditSelect";
import WaitSaveButton from "../components/WaitSaveButton";
import {EmailExistsCompanyModal} from "../components/RequestEdit/EmailExistsCompanyModal";
import {EmailExistsCompaniesModal} from "../components/RequestEdit/EmailExistsCompaniesModal";
import {ModalRecipientMembers} from "components/modals/ModalRecipientMembers";
import {ModalRecipientsFilter} from "components/modals/ModalRecipientsFilter";
import {RecipientsEditRow} from "../components/RequestEdit/RecipientsEditRow";
import {RecipientsEditEmptyCard} from "../components/RequestEdit/RecipientsEditEmptyCard";
import {Preloader} from "components/Preloader";
import {ColorRow} from "components/ColorRow";
import {LockValue} from "components/LockValue";
import {hasPermission} from "helpers/helper";
import {rfpStatuses} from "constants/buyer.consts";
import {rfpRecipientsFilterRow} from "constants/tabs.const";
import {color} from "constants/color.consts";

export const Recipients = inject("store")(
  observer(({store: {clientStore, auth, buyer, organization}}) => {
    const {user} = auth;
    const {favoritesOrganization, blacklistOrganization} = organization;
    const {
      currentRequest,
      isEdit,
      isNew,
      loading,
      rfpRecipientsFilterParams,
      newPublishedRFPRecipients,
      isPublishedRequest,
    } = buyer;
    const [isEmailExistsCompanyModal, setIsEmailExistsCompanyModal] = useState(null);
    const [isEmailExistsCompaniesModal, setIsEmailExistsCompaniesModal] = useState(null);
    const [isRecipientMembersModal, setIsRecipientMembersModal] = useState(null);
    const [isRecipientsFilterModal, setRecipientsFilterModal] = useState(false);
    const [isEmailSuggestCompanyModal, setIsEmailSuggestCompanyModal] = useState(null);
    const [invitedRecipient, setInvitedRecipient] = useState(null);
    const [filteredRecipients, setFilteredRecipients] = useState(null);
    const [openedRecipients, setOpenedRecipients] = useState([]);
    const [emailLoading, setEmailLoading] = useState(false);
    const filterRowItems = useMemo(
      () =>
        Object.entries(rfpRecipientsFilterParams)
          .filter((item) => Array.isArray(item[1]))
          .map((item) => item[1].map((value) => [item[0], value]))
          .flat()
          .sort((a, b) => (a[0] > b[0] ? -1 : 1)),
      [rfpRecipientsFilterParams]
    );
    const {t} = useTranslation();
    const isEditMode = useMemo(() => isNew || isEdit, [isNew, isEdit]);
    const canEdit = hasPermission(["EDIT_RFT"]);
    const isDraft = currentRequest?.status === rfpStatuses.DRAFT;

    const isFilledSelectRow = rfpRecipientsFilterParams.favorite
      ? Object.keys(rfpRecipientsFilterParams).length > 3
      : Object.keys(rfpRecipientsFilterParams).length > 2;

    const sumRegisteredRecipients = currentRequest?.recipients?.registered?.length;
    const sumNotRegisteredRecipients = currentRequest?.recipients?.notRegistered?.length;
    const sumRecipients = sumRegisteredRecipients + sumNotRegisteredRecipients;

    const searchHandyRecipients = async (value) =>
      await buyer.getCompaniesForRecipients(value);

    const removeHandyRecipients = (value) =>
      buyer.setRegisteredRecipients(value, "remove");
    const removeEmailRecipients = (value) => buyer.setEmailRecipients(value, "remove");
    const removeRecipients = (type, value) =>
      type === "email" ? removeEmailRecipients(value) : removeHandyRecipients(value);

    const updateHandyRecipients = (value) =>
      buyer.setRegisteredRecipients(value, "update");

    const addHandyRecipients = (value) =>
      organization.getAnotherOrganizationMembers(value.id).then((members) => {
        const rfpRegions = currentRequest.Project.availableIn ?? [];
        const MembersToReceive = rfpRegions.length
          ? members
              .filter(
                (member) =>
                  !!member.OrganizationMember.availableIn?.length &&
                  rfpRegions.some((region) =>
                    member.OrganizationMember.availableIn.includes(region)
                  )
              )
              .map((member) => ({id: member.id}))
          : [];
        MembersToReceive.length
          ? buyer.setRegisteredRecipients(
              {...value, MembersToReceive, Organization: {Users: members}},
              "add"
            )
          : buyer.setRegisteredRecipients(
              {...value, Organization: {Users: members}},
              "add"
            );
      });

    const addEmailRecipients = (value) => {
      const isNewEmailRecipient = currentRequest?.recipients?.notRegistered
        ?.map((item) => item.email)
        .includes(value.field);
      if (isNewEmailRecipient) return;
      setEmailLoading(true);
      buyer.checkEmailRecepient(value.field).then((data) => {
        setEmailLoading(false);
        if (data.status === "Not found")
          buyer.setEmailRecipients({email: value.field}, "add");
        if (data.status === "Several organizations found")
          setIsEmailExistsCompaniesModal(value.field);
        if (data.id && data.exact)
          setIsEmailExistsCompanyModal({
            email: value.field,
            id: data.id,
            organization: data.name,
            company: data.Company?.companyName,
            membersCount: data.membersCount ?? "--",
          });
        if (data.id && data.exact === false) {
          buyer.setEmailRecipients({email: value.field}, "add");
          setIsEmailSuggestCompanyModal({
            email: value.field,
            id: data.id,
            name: data.name,
            company: data.Company?.companyName,
            companyNumber: data.Company?.companyNumber,
            membersCount: data.membersCount ?? "--",
          });
          setTimeout(() => setIsEmailSuggestCompanyModal(null), 30000);
        } else return;
      });
    };

    const handleClickEmailRecipient = (email) =>
      isEmailSuggestCompanyModal?.email === email
        ? setIsEmailSuggestCompanyModal(null)
        : buyer.checkEmailRecepient(email).then(
            (data) =>
              data.id &&
              data.exact === false &&
              setIsEmailSuggestCompanyModal({
                email,
                id: data.id,
                name: data.name,
                company: data.Company?.companyName,
                companyNumber: data.Company?.companyNumber,
                membersCount: data.membersCount ?? "--",
              })
          );

    const addEmailOrganization = () =>
      addHandyRecipients({
        id: isEmailExistsCompanyModal.id,
        name: isEmailExistsCompanyModal.organization,
        membersCount: isEmailExistsCompanyModal.membersCount,
      });

    const convertEmailToOrganization = (organization) => {
      const realOrganizations = currentRequest.recipients.registered.filter(
        (item) => !!item.SellerId
      );
      // if the email domain matches the existing company
      // and this company has already been added to the recipients list, then exit
      if (realOrganizations.map((item) => item.SellerId).includes(organization.id))
        return;
      // otherwise, add the company to the recipients list
      addHandyRecipients({
        id: organization.id,
        name: organization.name,
        membersCount: organization.membersCount,
      });
    };

    const addRemoveRecipients = (value) =>
      currentRequest?.recipients?.registered.map((item) => item.id).includes(value.id)
        ? removeHandyRecipients(value.id)
        : addHandyRecipients(value);

    const clickEdit = async () => {
      if (!canEdit) return;
      await buyer.getCurrentRequestInfo(currentRequest.id);
      buyer.setEditStatus(true);
    };

    // requesting members of the selected organization and
    // pre-filling an empty array of members for notifications
    const onTagClick = (tag) => {
      organization.getAnotherOrganizationMembers(tag.id).then((members) => {
        const MembersToReceive = tag.MembersToReceive?.length
          ? tag.MembersToReceive
          : members
              .filter((item) => item?.OrganizationMember?.isRfpEmailNotifications)
              .map((item) => ({id: item.id}));
        updateHandyRecipients({...tag, MembersToReceive});
        setIsRecipientMembersModal({
          ...tag,
          members,
          MembersToReceive,
        });
      });
    };

    const copyInviteLink = (recipient) =>
      buyer
        .getInviteLink(currentRequest.id, recipient.email)
        .then((data) => {
          copyToClipboard(data.link, true);
          setInvitedRecipient(recipient);
          setTimeout(() => setInvitedRecipient(null), 10000);
        })
        .catch((e) => showError(e.response.data.error));

    const clearFilters = useCallback(() => {
      buyer.setRfpRecipientsFilterParams(defaultNameFieldSearch);
      setFilteredRecipients(null);
    }, [buyer]);

    const onSelectChange = (name, value) => {
      const params = {...rfpRecipientsFilterParams, [name]: value};
      if (!value || (Array.isArray(value) && !value.length)) delete params[name];
      buyer.setRfpRecipientsFilterParams(params);
      setFilteredRecipients(null);
      buyer.getCompaniesForFilteredRecipients().then(setFilteredRecipients);
      setRecipientsFilterModal(
        isRecipientsFilterModal ? true : Object.keys(params).length > 2
      );
    };

    const onChangeOrder = ({field, direction}) => {
      const params = {...rfpRecipientsFilterParams, field, direction};
      buyer.setRfpRecipientsFilterParams(params);
      setFilteredRecipients(null);
      buyer.getCompaniesForFilteredRecipients().then(setFilteredRecipients);
    };

    const handleRemoveFilterValue = (value) => {
      const deletedName = filterRowItems.find((item) => item[1] === value)?.[0];
      const deletedValue = filterRowItems
        .filter((item) => item[0] === deletedName && item[1] !== value)
        .map((item) => item[1]);
      deletedName && onSelectChange(deletedName, deletedValue);
    };

    const changeNotificationMembers = (members) => {
      const changedRecipient = {
        ...isRecipientMembersModal,
        MembersToReceive: members,
      };
      updateHandyRecipients(changedRecipient);
      setIsRecipientMembersModal(changedRecipient);
    };

    // pre-filling filters from project settings and RFT
    useEffect(() => {
      if (isEditMode) {
        buyer.setRfpRecipientsFilterParams(
          isFilledSelectRow
            ? {
                ...rfpRecipientsFilterParams,
                availableIn: currentRequest?.Project?.availableIn,
              }
            : {
                ...defaultNameFieldSearch,
                entrepreneurWithin: currentRequest?.entrepreneurWithin,
                entrepreneurOffers: currentRequest?.entrepreneurOffers,
                materialSupplierType: currentRequest?.materialSupplierType,
                consultingServices: currentRequest?.consultingServices,
                availableIn: currentRequest?.Project?.availableIn,
              }
        );
        setFilteredRecipients(null);
        organization.getFavorites();
        buyer.getCompaniesForFilteredRecipients().then(setFilteredRecipients);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buyer, isEditMode]);

    const loadMoreRecipients = async (page) =>
      buyer.getCompaniesForFilteredRecipients(page);

    return (
      <div className="recipients-tab_wrapper">
        {loading && <Preloader addClass="preloader250" />}
        {!loading && (
          <React.Fragment>
            {isEditMode ? (
              <React.Fragment>
                <div className="recipients-tab_overall-status pt-4">
                  <IconHandOnRight />
                  <span className="recipients-tab_overall-status_title mr-20">
                    {t("HAND_PICKED")}
                  </span>
                  <span className="recipients-tab_overall-status_count">
                    {+(currentRequest?.recipients?.registered?.length ?? null) +
                      +(currentRequest?.recipients?.notRegistered?.length ?? null)}
                  </span>
                </div>
                <WaitSaveButton />
                <ColorRow color="green" title="FIND_TYPE_REGION_LABEL">
                  <IconFunnel
                    height="30"
                    width="30"
                    color={color.green}
                    addClass="recipients-tab_row-icon"
                  />
                  <div className="filtered-section">
                    <CustomRadioSwitch
                      label={
                        <div className="df-row-center">
                          <IconCheckMark height={15} width={15} addClass="mr-1" />
                          {t("FILTERROW_OURSUPPLIERS_LABEL")}
                        </div>
                      }
                      checked={rfpRecipientsFilterParams?.favorite}
                      uppercase
                      small
                      addWrapperClass="recipients-tab_row-our-suppliers-toggle"
                      isActive={(checked) => onSelectChange("favorite", checked)}
                    />
                    <div className="mt-2 fg2 full-width">
                      <div className="df-row-wrap-center mt-2">
                        {rfpRecipientsFilterRow.map((selection) => (
                          <MultipleSelect
                            key={selection.label}
                            value={rfpRecipientsFilterParams?.[selection.label]}
                            list={selection.array}
                            customSelectAllElement={selection.selectAll}
                            tiny
                            addClass="mr-2 mb-2"
                            uppercaseButton
                            notShowingSelections
                            needSearch={selection.isNeedSearch}
                            color={selection.color}
                            bottomElement={selection.bottomElement}
                            onChange={(value) => onSelectChange(selection.label, value)}
                            title={selection.title}
                            titleInButton
                          />
                        ))}
                      </div>

                      {(Object.keys(rfpRecipientsFilterParams).length > 2 ||
                        isFilledSelectRow) &&
                        filteredRecipients && (
                          <SelectionRow
                            values={filterRowItems.map((item) => ({
                              value: item[1],
                              label: item[1],
                              color: item[1].includes("REGION") ? "blue" : "green",
                            }))}
                            tiny
                            suffixContent={
                              <React.Fragment>
                                <div className="extra-buttons">
                                  <ButtonTransparent
                                    small
                                    name={`${t("SHOW")} ${
                                      filteredRecipients?.totalItems
                                    } ${t("FILTERROW_SHOWRESULTS_BUTTON")}`}
                                    onClick={() => setRecipientsFilterModal(true)}
                                  />
                                  <ButtonTransparent
                                    name={t("CLEAR_FILTERS_BUTTON")}
                                    onClick={clearFilters}
                                    icon={
                                      <IconCross
                                        color={color.link}
                                        width="7"
                                        height="7"
                                      />
                                    }
                                    addClass="recipients-tab_row-results-clear-button"
                                    tiny
                                  />
                                </div>
                              </React.Fragment>
                            }
                            onRemove={handleRemoveFilterValue}
                          />
                        )}
                    </div>
                  </div>
                </ColorRow>
                <ColorRow color="green" title="FIND_COMPANY_LABEL">
                  <IconSearch
                    height="30"
                    width="30"
                    color={color.green}
                    addClass="recipients-tab_row-icon ma-4"
                  />
                  <div className="df-row-center">
                    <LockValue
                      canPermissions={["ADD_RECIPIENTS_IN_RFT"]}
                      needLabel
                      needShowModalToSubscribe
                    >
                      <RecipientsEditSelect
                        placeholder="INVITE_COMPANY_IN_HANDYDAY"
                        addClass="find-companies"
                        search={searchHandyRecipients}
                        add={addHandyRecipients}
                        favorites={favoritesOrganization}
                        blacklist={blacklistOrganization}
                        addPermissions={["ADD_RECIPIENTS_IN_RFT"]}
                        userPermissions={user.permissions}
                      />
                    </LockValue>
                  </div>
                </ColorRow>
                <ColorRow color="purple" border="top">
                  <IconMailLetter
                    height="30"
                    width="30"
                    color={color.purple}
                    addClass="recipients-tab_row-icon ml-4 mr-4"
                  />
                  <LockValue
                    canPermissions={["ADD_RECIPIENTS_IN_RFT"]}
                    needLabel
                    needShowModalToSubscribe
                  >
                    <AddableFormikField
                      type="email"
                      allowEmpty
                      small
                      initialValue=""
                      loading={emailLoading}
                      iconColor="purple"
                      placeholder="INVITE_SOMEONE_BY_EMAIL"
                      addClass="recipients-tab_email-field"
                      add={addEmailRecipients}
                      addPermissions={["ADD_RECIPIENTS_IN_RFT"]}
                    />
                  </LockValue>
                </ColorRow>
                {!sumRecipients}
                {!!sumRecipients && (
                  <div className="df mt-8">
                    <h2 className="recipients-tab_table-title">
                      {t("RESULTING_RECIPIENTS_TITLE")}
                    </h2>
                    <span className="recipients-tab_table-count">{sumRecipients}</span>
                    {/* {!!sumInactiveRecipients && (
                      <span className="recipients-tab_table-count inactive">
                        {sumInactiveRecipients}
                      </span>
                    )} */}
                  </div>
                )}
                <div>
                  {!!sumRegisteredRecipients &&
                    currentRequest?.recipients?.registered.map((recipient, index) => (
                      <RecipientsEditRow
                        key={index}
                        type="organization"
                        organization={recipient}
                        currentUser={user}
                        remove={(value) => removeRecipients("organization", value)}
                        isFavorite={favoritesOrganization.some(
                          (item) => item.id === recipient.id
                        )}
                        isBlacklisted={blacklistOrganization.some(
                          (item) => item.id === recipient.id
                        )}
                        isShowInfoLabel={!openedRecipients.includes(recipient.id)}
                        onTagClick={onTagClick}
                        dontRemove={newPublishedRFPRecipients.notRemoveRegistered}
                        isPublishedRequest={isPublishedRequest}
                      />
                    ))}
                  {!!sumNotRegisteredRecipients &&
                    currentRequest?.recipients?.notRegistered.map((recipient, index) => (
                      <RecipientsEditRow
                        key={index}
                        type="email"
                        remove={(value) => removeRecipients("email", value)}
                        isSuggestModal={
                          isEmailSuggestCompanyModal?.email === recipient.email
                        }
                        selectedModalOrganization={isEmailSuggestCompanyModal}
                        onConvertEmailToOrganization={convertEmailToOrganization}
                        onTagClick={() => handleClickEmailRecipient(recipient.email)}
                        closeSuggestModal={() => setIsEmailSuggestCompanyModal(null)}
                        organization={recipient}
                        dontRemove={newPublishedRFPRecipients.notRemoveNotRegistered}
                        isPublishedRequest={isPublishedRequest}
                      />
                    ))}
                </div>

                {!!sumRecipients && <WaitSaveButton inText addClass="mt-4" />}
                {isEmailExistsCompanyModal && (
                  <EmailExistsCompanyModal
                    data={isEmailExistsCompanyModal}
                    onAdd={addEmailOrganization}
                    onClose={() => setIsEmailExistsCompanyModal(null)}
                  />
                )}
                {isEmailExistsCompaniesModal && (
                  <EmailExistsCompaniesModal
                    email={isEmailExistsCompaniesModal}
                    onClose={() => setIsEmailExistsCompaniesModal(null)}
                  />
                )}
                {isRecipientMembersModal && (
                  <ModalRecipientMembers
                    organization={isRecipientMembersModal}
                    onClose={() => {
                      setIsRecipientMembersModal(null);
                      setOpenedRecipients([
                        ...openedRecipients,
                        isRecipientMembersModal.id,
                      ]);
                    }}
                    currentUser={user}
                    onAddEmailRecipients={addEmailRecipients}
                    onChoiceNotificationMember={changeNotificationMembers}
                    emailLoading={emailLoading}
                  />
                )}
                {isRecipientsFilterModal && (
                  <ModalRecipientsFilter
                    filteredRecipients={filteredRecipients}
                    addedRecipients={currentRequest?.recipients?.registered}
                    filterRowItems={filterRowItems}
                    filterParams={rfpRecipientsFilterParams}
                    onSelectChange={onSelectChange}
                    onChangeOrder={onChangeOrder}
                    onActionClick={addRemoveRecipients}
                    onRemoveSelection={handleRemoveFilterValue}
                    onClose={() => {
                      !isFilledSelectRow && clearFilters();
                      setRecipientsFilterModal(false);
                    }}
                    loadMoreRecipients={loadMoreRecipients}
                  />
                )}
                <RecipientsEditEmptyCard language={clientStore.language} />
              </React.Fragment>
            ) : (
              <div>
                <div className="recipients-tab_overall-status pt-4">
                  <span className="recipients-tab_overall-status_title mr-20">
                    {t("RECIPIENTS")}
                  </span>
                  <span className="recipients-tab_overall-status_count">
                    {+(sumRegisteredRecipients ?? null) +
                      +(sumNotRegisteredRecipients ?? null)}
                  </span>
                </div>
                {sumRegisteredRecipients || sumNotRegisteredRecipients ? (
                  <div>
                    <RecipientsHead hideAdditionalInfo={isDraft} />
                    {!!sumRegisteredRecipients &&
                      currentRequest?.recipients?.registered.map((recipient) => (
                        <RecipientItem
                          key={recipient.id}
                          recipient={recipient}
                          hideAdditionalInfo={isDraft}
                          remindRecipient={() =>
                            buyer
                              .remindRfpRecipient(currentRequest.id, recipient.id)
                              .then(() => {
                                showSuccess(t("SUCCESSFULLY_SENT_REMINDER"));
                                buyer.getCurrentRequestInfo(currentRequest.id);
                              })
                          }
                          registered
                        />
                      ))}
                    {!!sumNotRegisteredRecipients &&
                      currentRequest?.recipients?.notRegistered.map((recipient) => (
                        <RecipientItem
                          key={recipient.id}
                          recipient={recipient}
                          hideAdditionalInfo={isDraft}
                          invitedRecipient={invitedRecipient}
                          reminderDays={currentRequest.reminderDays}
                          onClickLinkButton={() => copyInviteLink(recipient)}
                        />
                      ))}
                    {!!sumNotRegisteredRecipients && (
                      <LockValue
                        canPermissions={["ENABLE_RFT_RECIPIENTS_INVITE_LINK"]}
                        needLabel
                        justHide
                      >
                        <Explanation
                          width={47}
                          text="INVITE_LINK_EXPLANATION"
                          icon={<IconMailLetter width="21" height="13" addClass="mt-1" />}
                          addClass="mt-8"
                        />
                      </LockValue>
                    )}
                  </div>
                ) : (
                  <LockValue
                    canPermissions={["EDIT_RFT"]}
                    needLabel
                    needShowModalToSubscribe
                    noLockIcon
                  >
                    <div>
                      <p>{t("SELECT_RECIPIENTS_DESCRIPTION")}</p>
                      <ButtonTransparent
                        addClass="mt-8"
                        name={t("ADD_RECIPIENTS")}
                        type="button"
                        icon={<IconPlus />}
                        onClick={clickEdit}
                      />
                    </div>
                  </LockValue>
                )}
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    );
  })
);
