import React from "react";
// locale
import PresentationCardWrapper from "../../PresentationCardWrapper";
import SearchPreferences from "./components/SearchPreferences";
import EmptyData from "components/EmptyData";
import SearchPreferencesEdit from "pages/PresentationPage/components/ServicesComponents/ServicesOffered/components/SearchPreferencesEdit/SearchPreferencesEdit";
import {inject, observer} from "mobx-react";
import {Explanation} from "components/Explanation";

const ServicesOffered = inject("store")(
  observer(({store: {organization}, edit, setEdit}) => {
    const {organizationDetail} = organization;
    const isActive = organizationDetail?.isActive;

    const closeEdit = () => setEdit({...edit, services: false});

    if (edit.services) return <SearchPreferencesEdit closeEdit={closeEdit} />;

    const fields = organizationDetail.SearchPreference ? (
      <SearchPreferences searchPreference={organizationDetail.SearchPreference} />
    ) : (
      <EmptyData />
    );

    return (
      <PresentationCardWrapper
        edit={edit}
        setEdit={setEdit}
        editName="services"
        editPermissions={["EDIT_OUR_PROFILE_MAIN_INFO"]}
        isActive={isActive}
      >
        {!isActive && (
          <Explanation
            text="INFO_ABOUT_INCORRECT_CATEGORIES"
            fitWidth
            textCenter
            thinVPadding
            addClass="mb-8 df-row-center"
          />
        )}
        {fields}
      </PresentationCardWrapper>
    );
  })
);
export default ServicesOffered;
