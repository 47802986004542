import React from "react";
import {IconFavoriteDone, IconCheckMark} from "components/icons";
import {color} from "constants/color.consts";
import {CustomTooltip} from "components/CustomTooltip";
import {ConfirmModal} from "components/ConfirmModal";
import i18next from "i18next";
import * as qr from "querystring-es3";
import {v4 as uuidv4} from "uuid";
// locale
import {SAVE_SEARCH} from "constants/routes.const";
import {
  selectFieldName,
  defaultDirectionsByField,
  subscriptionPriceKeys,
} from "constants/user.consts";
import {getStorage, removeStorage} from "./storage";
import AuthStore from "../store/auth.store";
import {showSuccess, showInfo} from "./notifications.helper";

export const checkField = (msg) => {
  const str = msg.replace(/([A-Z])/g, " $1").toLowerCase();
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export function updateLocationHash(hash) {
  window.location.hash = hash;
}

const checkJoinRequest = (auth) => {
  const {joinRequest} = auth;
  if (auth && joinRequest && joinRequest.length) {
    const {
      Company: {companyNumber},
      OrganizationJoinRequest: {OrganizationId},
    } = joinRequest[0];
    return {companyNumber, organizationId: OrganizationId};
  } else {
    return {companyNumber: getStorage("companyNumber"), organizationId: null};
  }
};

export const createNewOrganization = (organization, auth, navigate, companyName) => {
  return () => {
    organization.createNewOrg(checkJoinRequest(auth), companyName).then(() => {
      auth.checkToken().then(() => {
        removeStorage("companyNumber");
        navigate(SAVE_SEARCH);
      });
      auth.updateAuthModalName();
    });
  };
};

export const digitsBy2 = (x) => {
  return Number.parseFloat(x).toFixed(2);
};

export const createArray = (lng) => Array.from(new Array(lng));

export const createLanguageKeysArray = (length, baseKey, withoutZero) =>
  createArray(length).map(
    (_, number) =>
      `${baseKey}_${
        !withoutZero && `${number}`.length < 2 ? `0${number + 1}` : number + 1
      }`
  );

export const constTemplate = ({name, number, withoutZero}) => {
  name = name.toString().toUpperCase();
  number += 1;
  number = number.toString();
  number = !withoutZero && number.length < 2 ? `0${number}` : number;
  return {
    label: `${name}_${number}`,
    value: `${name}_${number}`,
  };
};

export const openNewTab = (url) => {
  url = url.match(/^http[s]?:\/\//) ? url : `http://${url}`;
  window.open(url, "_blank");
};

export const toExternalLink = (url) => {
  url = url.match(/^http[s]?:\/\//) ? url : `http://${url}`;
  window.location = url;
};

export const trimString = (str, length = 20) => {
  if (str) {
    if (str.length <= length)
      return str.length <= length ? str : `${str.substring(0, length)}...`;
  }
};

export const getDomain = (url, subdomain) => {
  if (!url) return null;

  subdomain = subdomain || false;

  url = url.replace(/(https?:\/\/)?(www.)?/i, "");

  if (!subdomain) {
    url = url.split(".");

    url = url.slice(url.length - 2).join(".");
  }

  if (url.indexOf("/") !== -1) return url.split("/")[0];

  return url;
};

export const sumKeyValueObjectsInArray = (items, key) => {
  if (Array.isArray(items)) {
    return items.reduce((a, b) => a + (b[key] || 0), 0);
  }
};

export const getInitials = (firstName, lastName) => {
  return `${firstName ? firstName.trim()[0] : ""}${
    lastName ? lastName.trim()[0] : ""
  }`.toUpperCase();
};

export const getFullName = (firstName, lastName) =>
  `${firstName ?? ""} ${lastName ?? ""}`.trim();

export const formatCompanyNumber = (value, restore) => {
  /***
   * return company format as xxxx-xxxxxx
   * else if restore === true, return restored value (xxxx-xxxxxx -> xxxxxxxxxx)
   * ***/
  if (!value) return "";

  if (restore === true) return value.toString().trim().replace(/[-/x]/g, "");

  if (value.length > 5) return `${value.substring(0, 6)}-${value.substring(6)}`;
  return value;
};

export const getFiles = (item) => {
  if (item?.Files?.length) {
    return item.Files.map((file) => ({
      url: file.url,
      name: file.name,
    }));
  }
  return null;
};

export const getMessage = (message) => {
  const language = i18next.language;
  if (typeof message === "string") {
    return message;
  } else if (typeof message === "object" && Object.keys(message).length) {
    return message[language];
  }
};

export const newQuerySearch = (searchString) => {
  const query = qr.parse(searchString.replace("?", ""));
  const newQuery = {};
  Object.keys(query).forEach((item) => {
    const queryParam = query[item];
    const queryParamArray = queryParam.split(",");
    if (item.includes("[]")) {
      newQuery[item.replace("[]", "")] = queryParamArray;
    } else newQuery[item] = query[item];
  });
  return newQuery;
};

export const changeSelectValue = (list, filterParams) =>
  list[0].value.includes(filterParams?.field?.toUpperCase())
    ? list.find((item) => item.value.includes(filterParams.direction)).value
    : list[0].value;

export const changeFilterParams = (value) => {
  const field = selectFieldName[value.split("_")[0]] ?? "";
  const direction = value.split("_")[1] ?? "";
  const params = {field, direction};
  if (!value) {
    delete params["direction"];
  }
  return params;
};

export const directionReplacement = (filterParams) =>
  filterParams.direction && filterParams.direction === "default"
    ? defaultDirectionsByField[filterParams.field]
    : filterParams.direction;

export const copyToClipboard = (text, noMessage, message) => {
  const textField = document.createElement("textarea");
  textField.innerText = text;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand("copy");
  textField.remove();
  !noMessage && showInfo(message ?? "Text copied", 2);
};

export const parentElementIdWidth = (id) =>
  document.getElementById(id)?.parentElement?.clientWidth ?? 0;

export const parentElementIdHeight = (id) =>
  document.getElementById(id)?.parentElement?.clientHeight ?? 0;

export const hasPermission = (canPermissions) =>
  canPermissions?.some((permission) =>
    AuthStore?.user?.permissions?.map((item) => item.PermissionId).includes(permission)
  ) ?? false;

export const hasPermissions = (canPermissions) =>
  canPermissions?.every((permission) =>
    AuthStore?.user?.permissions?.map((item) => item.PermissionId).includes(permission)
  ) ?? false;

export const hasCountPermission = (canPermission, countPermission, userPermissions) =>
  userPermissions?.find((permission) => permission.PermissionId === canPermission)
    .quantity > countPermission ?? false;

export const hasUserPermission = (actionsRoles, clientRole) => {
  return actionsRoles.includes(clientRole);
};

export const saveDocument = (data, type, fileName, message) => {
  const blob = new Blob([data], {type});
  const csvURL = window.URL.createObjectURL(blob);
  let tempLink = document.createElement("a");
  tempLink.href = csvURL;
  tempLink.setAttribute("download", fileName);
  tempLink.click();
  !!message && showSuccess(message);
  window.URL.revokeObjectURL(blob);
  tempLink.remove();
};

export const isObject = (obj) => obj === Object(obj);

export const arrayToCommaAnd = (items, lastAnd = " and ", comma = ", ") =>
  items.reduce(
    (str, item, i) =>
      (str += `${item}${
        i < items.length - 2 ? comma : i < items.length - 1 ? lastAnd : ""
      }`),
    [""]
  );

export const hasTranslation = (key) => i18next.t(key) !== key;

export const chooseFavoriteIcon = (isGreenIcon, isEmptyIcon) =>
  isGreenIcon ? (
    isEmptyIcon ? (
      <IconCheckMark color={color.green} addClass="mr-2" />
    ) : (
      <IconFavoriteDone color={color.green} addClass="mr-2" />
    )
  ) : (
    <CustomTooltip text={"SUPPLIER_QUALIFICATION_NOT_PASSED_QUALIFICATION"}>
      <div style={{height: "20px", display: "inline", marginRight: "10px"}}>
        {isEmptyIcon ? (
          <IconCheckMark color={color.gold} />
        ) : (
          <IconFavoriteDone color={color.gold} />
        )}
      </div>
    </CustomTooltip>
  );

export const hasNoLegacySupplierQualification = (
  isTitleIcon,
  isFavorite,
  hasSuppliersQualifications,
  isEnableSuppliersQualification,
  isEmptyIcon
) =>
  (isTitleIcon && isFavorite) ||
  (!isTitleIcon && !hasSuppliersQualifications && isEnableSuppliersQualification) ? (
    chooseFavoriteIcon(
      hasSuppliersQualifications || !isEnableSuppliersQualification,
      isEmptyIcon
    )
  ) : (
    <React.Fragment></React.Fragment>
  );

// Augment String.prototype to allow for easier formatting.  This implementation
// doesn't completely destroy any existing String.prototype.format functions,
// and will stringify objects/arrays. (arguments into string - {0}, {1}, etc.)
// eslint-disable-next-line no-extend-native
String.prototype.format = (function (i, safe, arg) {
  function format() {
    let str = this;
    let len = arguments.length + 1;
    for (i = 0; i < len; arg = arguments[i++]) {
      safe = typeof arg === "object" ? JSON.stringify(arg) : arg;
      str = str.replace(RegExp("\\{" + (i - 1) + "\\}", "g"), safe);
    }
    return str;
  }
  format.native = String.prototype.format;
  return format;
})();

export const subscriptionPrice = (subscription, period) =>
  subscription[subscriptionPriceKeys[period]]?.replace(".00", "");

export const parseUrlParams = (params) =>
  Object.entries(params).reduce(
    (obj, item) => ({
      ...obj,
      [item[0].replace("[]", "")]: item[0].includes("[]") ? item[1].split(",") : item[1],
    }),
    {}
  );

export const getUrlParams = (params, additionalParams, objectParamsOnly) => {
  const urlParams = Object.entries(params).reduce(
    (obj, item) => ({
      ...obj,
      [`${item[0]}${Array.isArray(item[1]) ? "[]" : ""}`]: item[1],
    }),
    {}
  );
  const isEmpty = !Object.keys(urlParams).length;
  return isEmpty
    ? ""
    : objectParamsOnly
    ? {...urlParams, ...(additionalParams ?? {})}
    : `?${new URLSearchParams({...urlParams, ...(additionalParams ?? {})})}`;
};
export const getUserDropdownList = (users) =>
  users
    ?.map((user) => ({
      value: user.id,
      label: getFullName(user.firstName, user.lastName),
    }))
    ?.filter((user) => user.label) ?? [];

export const notNull = (value) => value !== null;
export const isNull = (value) => value === null;

export const notUndefined = (value) => value !== undefined;
export const isUndefined = (value) => value === undefined;

export const obfuscatePersonalNumber = (pnr) => `${pnr.slice(0, -4)}****`;

/* eslint-disable no-extend-native */
Array.prototype.collatorSortString = function (language) {
  return this.slice().sort((a, b) =>
    new Intl.Collator(language, {
      sensitivity: "variant",
      caseFirst: "upper",
    }).compare(a, b)
  );
};

/* eslint-disable no-extend-native */
Array.prototype.collatorSortObj = function (language, key) {
  return this.slice().sort((a, b) =>
    new Intl.Collator(language, {
      sensitivity: "variant",
      caseFirst: "upper",
    }).compare(a[key], b[key])
  );
};

/* eslint-disable no-extend-native */
Array.prototype.noop = function () {
  return this;
};

export const isEmptyObject = (obj) => {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) return false;
  }
  return true;
};

export const filterObjectValidKeys = (obj) =>
  Object.fromEntries(Object.entries(obj).filter(([_, value]) => value != null));

export const formFileData = (values) => {
  const data = new FormData();
  data.append("file", values);
  return data;
};

export const uploadFiles = (files, s3Upload, updateEntity, setLoading) => {
  !!setLoading && setLoading(true);
  const s3UploadFiles = files.map((file) => s3Upload(file));
  Promise.all(s3UploadFiles)
    .then(
      (results) =>
        !!updateEntity &&
        updateEntity(results.map((file) => ({id: uuidv4(), File: file})))
    )
    .finally(() => !!setLoading && setLoading(false));
};

export const removeFilesConfirmModal = (id, remove) =>
  ConfirmModal({
    title: i18next.t(id ? "WANT_DELETE_FILE" : "WANT_DELETE_ALL_FILES"),
    text: i18next.t(id ? "CANT_DELETE_FILE" : "CANT_DELETE_ALL_FILES"),
    type: "warning",
    onOk: () => remove(id),
  });

export const enableEntity = (entity, rule) => (rule ? entity : null);
export const disableEntity = (entity, rule) => (rule ? null : entity);
