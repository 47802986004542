export const color = {
  black: "#000000",
  blue: "#1BA2CC",
  almostBlue: "#2EC0EE",
  link: "#1BA2CC",
  midGray: "#a9a9a9",
  lightGray: "#d8d8d8",
  lightYellow: "#ffff84",
  darkGray: "#3e3e3e",
  whiteGray: "#efefef",
  white: "#ffffff",
  gold: "#ffce54",
  dimmedOpaqueWhite: "rgba(255, 255, 255, 0.6)",
  offWhite: "#fafafa",
  discreteText: "#626262",
  red: "#C24040",
  pink: "#F8EAEA",
  green: "#61B05E",
  whiteGreen: "#E3F3E3",
  orange: "#C17E2E",
  purple: "#B762CC",
  iconGradient: "linear-gradient(180deg, #1ba2cc 0%, #6bc069 100%)",
  linkOpaqueBg: "rgba(27, 162, 204, 0.05)",
  linkHover: "rgba(27, 162, 204, 0.1)",
  greenLightOpaque: "rgba(107, 192, 105, 0.1)",
  redLightOpaque: "rgba(186, 46, 46, 0.1)",
  blackLightOpaque: "rgba(0, 0, 0, 0.03)",
  searchBorder: "#e2e2e2",
};
