import React, {memo} from "react";

export const IconFolderDalux = memo(({color, height, width, addClass}) => {
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5 5H11C10.914 5 10.762 4.906 10.724 4.829L10.171 3.723C9.965 3.31 9.461 2.999 9 2.999H2C1.539 2.999 1.035 3.31 0.829 3.723L0.276 4.829C0.118 5.144 0 5.647 0 6V16.5C0 17.327 0.673 18 1.5 18H18.5C19.327 18 20 17.327 20 16.5V6.5C20 5.673 19.327 5 18.5 5ZM19 16.5C19 16.776 18.776 17 18.5 17H1.5C1.224 17 1 16.776 1 16.5V6C1 5.804 1.083 5.452 1.171 5.276L1.724 4.17C1.762 4.093 1.915 3.999 2 3.999H9C9.086 3.999 9.238 4.093 9.276 4.17L9.829 5.276C10.035 5.689 10.539 6 11 6H18.5C18.776 6 19 6.224 19 6.5V16.5Z"
        fill="black"
      />
      <rect x="8.09998" y="11.4" width="4.8" height="3.2" fill="#8DC63F" />
      <path
        d="M7.58421 11.3905H6.5L10.5 8.20001V9.0762L7.58421 11.3905Z"
        fill="#3E5867"
      />
      <path d="M13.4158 11.4H14.5L10.5 8.20954V9.08573L13.4158 11.4Z" fill="#3E5867" />
    </svg>
  );
});
