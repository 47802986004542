import React, {useState, useEffect, useCallback, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
import {debounce} from "lodash";
import {v4 as uuidv4} from "uuid";
//local
import {Tabs} from "antd";
import {Modal} from "components/Modal";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import ButtonDelete from "components/buttons/ButtonDelete";
import SmartFormQuestions from "pages/OurRequests/components/SmartForm/SmartFormQuestions";
import {Preloader} from "components/Preloader";
import {Input} from "components/Input";
import {CustomTooltip} from "components/CustomTooltip";
import {ConfirmModal} from "components/ConfirmModal";
import {IconChevronLeft, IconPlus, IconList, IconEdit} from "components/icons";
import {dateFormat} from "helpers/datetime";
import {smartTemplateTabs} from "constants/tabs.const";
import {VideoInstructionLink} from "components/VideoInstructionLink";
import {
  emptySmartFormQuestion,
  emptySmartFormHeadingQuestion,
} from "constants/buyer.consts";
import {color} from "constants/color.consts";

export const SmartTemplatesModal = inject("store")(
  observer(
    ({
      store: {auth, organization, clientStore},
      defaultTab,
      disabledTabs,
      isAdminOwner,
      onChangeTemplate,
      onClose,
    }) => {
      const {t} = useTranslation();
      const {user} = auth;

      const [activeTab, setActiveTab] = useState(smartTemplateTabs[defaultTab ?? 0].id);
      const [smartOrganizationTemplates, setSmartOrganizationTemplates] = useState(null);
      const [smartMyTemplates, setSmartMyTemplates] = useState(null);
      const [editTemplateId, setEditTemplateId] = useState(null);
      const [editTemplate, setEditTemplate] = useState(null);
      const [initialEditTemplate, setInitialEditTemplate] = useState(null);

      const loading = useMemo(
        () =>
          !(disabledTabs[1] || !!smartOrganizationTemplates) ||
          !(disabledTabs[0] || !!smartMyTemplates),
        [smartOrganizationTemplates, disabledTabs, smartMyTemplates]
      );
      const isNewTemplate = useMemo(() => editTemplateId === "new", [editTemplateId]);
      const hasUnsavedChanges = useMemo(
        () => JSON.stringify(initialEditTemplate) !== JSON.stringify(editTemplate),
        [initialEditTemplate, editTemplate]
      );
      const isDisabledSaveButton = useMemo(
        () => !editTemplate?.name || !hasUnsavedChanges,
        [editTemplate, hasUnsavedChanges]
      );
      const isListTemplates = useMemo(() => {
        if (activeTab === 0) return !!smartMyTemplates?.length;
        else if (activeTab === 1) return !!smartOrganizationTemplates?.length;
        else return false;
      }, [activeTab, smartMyTemplates, smartOrganizationTemplates]);

      const getOrganizationTemplates = useCallback(() => {
        setSmartOrganizationTemplates(null);
        organization
          .getOrganizationSmartTemplates()
          .then((data) => setSmartOrganizationTemplates(data ?? []));
      }, [organization]);
      const getMyTemplates = useCallback(() => {
        setSmartMyTemplates(null);
        clientStore.getUserSmartTemplates().then(setSmartMyTemplates);
      }, [clientStore]);

      const getTemplates = useCallback(async () => {
        if (!disabledTabs[1]) getOrganizationTemplates();
        if (!disabledTabs[0]) getMyTemplates();
      }, [getOrganizationTemplates, getMyTemplates, disabledTabs]);

      const getTemplate = useCallback(
        async (id) => {
          const template = activeTab
            ? await organization.getOrganizationSmartTemplate(id)
            : await clientStore.getUserSmartTemplate(id);
          setEditTemplate(template);
          setInitialEditTemplate(template);
          return template;
        },
        [activeTab, organization, clientStore]
      );

      const addNewTemplate = () => {
        setEditTemplateId("new");
        setEditTemplate({
          name: "",
          smartFormQuestions: [
            {id: uuidv4(), ...emptySmartFormHeadingQuestion},
            {id: uuidv4(), ...emptySmartFormQuestion},
          ],
        });
      };

      const clearTemplate = () => {
        setEditTemplateId(null);
        setEditTemplate(null);
      };

      const handleRemoveTemplate = () =>
        ConfirmModal({
          title: t("SMART_TEMPLATES_MODAL_TEMPLATE_WANT_DELETE"),
          text: t("SMART_TEMPLATES_MODAL_TEMPLATE_CANT_RETURN"),
          type: "warning",
          onOk: removeTemplate,
        });

      const removeTemplate = async () => {
        activeTab
          ? await organization.removeOrganizationSmartTemplate(editTemplateId)
          : await clientStore.removeUserSmartTemplate(editTemplateId);
        clearTemplate();
        activeTab ? await getOrganizationTemplates() : await getMyTemplates();
      };

      const handleChangeTemplateName = (e) =>
        setEditTemplate((prevState) => ({...prevState, name: e.target.value}));

      const changeSmartFormQuestions = debounce((value) => {
        setEditTemplate((prevState) => ({...prevState, smartFormQuestions: value}));
      }, 1000);

      const handleSaveTemplate = async () => {
        if (activeTab) {
          isNewTemplate
            ? await organization.createOrganizationSmartTemplate(editTemplate)
            : await organization.saveOrganizationSmartTemplate(
                editTemplateId,
                editTemplate
              );
          clearTemplate();
          await getOrganizationTemplates();
        } else {
          isNewTemplate
            ? await clientStore.createUserSmartTemplate(editTemplate)
            : await clientStore.saveUserSmartTemplate(editTemplateId, editTemplate);
          clearTemplate();
          await getMyTemplates();
        }
      };

      const handleSelectTemplate = (id) => {
        getTemplate(id).then((template) => {
          onChangeTemplate(template.smartFormQuestions);
          onClose();
        });
      };

      const handleChangeTab = (tabId) =>
        editTemplateId && editTemplate && hasUnsavedChanges
          ? ConfirmModal({
              title: t("SMART_TEMPLATES_MODAL_TEMPLATE_WANT_SAVE"),
              text: t("SMART_TEMPLATES_MODAL_TEMPLATE_CANT_SAVE"),
              type: "warning",
              onOk: () => changeTab(tabId),
            })
          : changeTab(tabId);

      const changeTab = (tabId) => {
        clearTemplate();
        setActiveTab(tabId);
      };

      const TemplateRow = ({template}) => (
        <div
          className={`modal-smart-templates__table-row${
            !!onChangeTemplate ? " cpointer" : ""
          }`}
          onClick={() => !!onChangeTemplate && handleSelectTemplate(template.id)}
        >
          <div className="modal-smart-templates__table-row-name">{template.name}</div>
          <div className="modal-smart-templates__table-row-number">
            {template.smartFormQuestionsCount ?? "-"}
          </div>
          <div className="modal-smart-templates__table-row-date">
            {dateFormat(template.updatedAt)}
          </div>
          <div className="modal-smart-templates__table-row-actions">
            {(activeTab === 1 ? isAdminOwner : true) && (
              <ButtonTransparent
                addClass="pa-0 mla"
                small
                noBorder
                icon={<IconEdit color={color.black} addClass="mr-4" />}
                onClick={(e) => {
                  e.stopPropagation();
                  setEditTemplateId(template.id);
                }}
              />
            )}
          </div>
        </div>
      );

      useEffect(() => {
        getTemplates();
      }, [getTemplates]);

      useEffect(() => {
        editTemplateId && !isNewTemplate && getTemplate(editTemplateId);
      }, [editTemplateId, getTemplate, isNewTemplate]);

      return (
        <Modal
          onClose={onClose}
          percentageWide="100%"
          boldTitle
          noCenterContent
          titleIcon={<IconList addClass="mr-4" />}
          titleText="SMART_TEMPLATES_MODAL_TITLE"
          addClass="modal-smart-templates_wrapper"
          contentClassName="modal-smart-templates"
        >
          <div>
            {loading && <Preloader addClass="preloader250" />}
            {!loading && (
              <React.Fragment>
                <Tabs
                  defaultActiveKey={activeTab}
                  activeKey={activeTab}
                  onChange={handleChangeTab}
                  centered
                  items={smartTemplateTabs.map((item, index) => ({
                    label: (
                      <React.Fragment>
                        <div>{t(item.title)}</div>
                        <div className="fz-12 __normal mid-gray">
                          {t(item.description)}
                        </div>
                      </React.Fragment>
                    ),
                    key: item.id,
                    disabled: disabledTabs[index],
                  }))}
                  className="modal-smart-templates__tabs"
                />
                {editTemplateId ? (
                  <div className="modal-smart-templates__tab-content pt-8">
                    <ButtonTransparent
                      icon={<IconChevronLeft color={color.blue} />}
                      noBorder
                      uppercase
                      bold
                      small
                      addClass="pl-0"
                      name={t(smartTemplateTabs[activeTab].title)}
                      onClick={clearTemplate}
                    />
                    {(editTemplate || isNewTemplate) && (
                      <div className="modal-smart-templates__template-head">
                        <Input
                          small
                          boldText
                          width="300px"
                          customHeight="40"
                          fontSize="16"
                          placeholder={t(
                            "SMART_TEMPLATES_MODAL_TEMPLATE_NAME_PLACEHOLDER"
                          )}
                          className="input ml-4"
                          value={editTemplate?.name}
                          onChange={handleChangeTemplateName}
                        />
                        <ButtonDelete
                          addClass="ml-2"
                          onClick={handleRemoveTemplate}
                          activeOnHover
                        />
                        <CustomTooltip
                          text={"SMART_TEMPLATES_MODAL_TEMPLATE_BUTTON_TOOLTIP"}
                          disabled={!isDisabledSaveButton}
                          centerText
                        >
                          <div className="pa-0 df mla">
                            <ButtonTransparent
                              disabled={isDisabledSaveButton}
                              name={"SAVE_CHANGES"}
                              medium
                              nonTransparent
                              onClick={handleSaveTemplate}
                            />
                          </div>
                        </CustomTooltip>
                      </div>
                    )}
                    {(editTemplate || isNewTemplate) && (
                      <div className="modal-smart-templates__template-body">
                        <SmartFormQuestions
                          isNew={isNewTemplate}
                          isEdit={true}
                          titleHide
                          permissions={user.permissions}
                          questions={editTemplate?.smartFormQuestions ?? []}
                          onChange={changeSmartFormQuestions}
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <div
                    className={`modal-smart-templates__tab-content${
                      isListTemplates ? "" : " pt-8"
                    }`}
                  >
                    {isListTemplates ? (
                      <React.Fragment>
                        <div className="modal-smart-templates__table-head">
                          <div className="modal-smart-templates__table-head-name">
                            {t("SMART_TEMPLATES_MODAL_TABLE_COLUMN_NAME")}
                          </div>
                          <div className="modal-smart-templates__table-head-number">
                            {t("SMART_TEMPLATES_MODAL_TABLE_COLUMN_NUMBER")}
                          </div>
                          <div className="modal-smart-templates__table-head-date">
                            {t("SMART_TEMPLATES_MODAL_TABLE_COLUMN_DATE")}
                          </div>
                          <div className="modal-smart-templates__table-head-actions"></div>
                        </div>
                        <div className="modal-smart-templates__table-body">
                          {activeTab === 0 &&
                            smartMyTemplates?.map((template) => (
                              <TemplateRow key={template.id} template={template} />
                            ))}
                          {activeTab === 1 &&
                            smartOrganizationTemplates?.map((template) => (
                              <TemplateRow key={template.id} template={template} />
                            ))}
                        </div>
                      </React.Fragment>
                    ) : (
                      <div className="modal-smart-templates__table-body">
                        <div className="null-state-wrapper pa-3">
                          <div className="mb-2">
                            {t(
                              `SMART_TEMPLATES_MODAL_TAB_0${activeTab + 1}_NO_TEMPLATES`
                            )}
                          </div>
                          <div>
                            <VideoInstructionLink
                              color="black"
                              label="VIDEO_LINK_SMART_TEMPLATES_LABEL"
                              link="VIDEO_LINK_SMART_TEMPLATES_LINK"
                              addClass="mt-1"
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    {(activeTab === 1 ? isAdminOwner : true) && (
                      <div className="df-row-jce-center mt-4">
                        <ButtonTransparent
                          name="SMART_TEMPLATES_MODAL_NEW_BUTTON"
                          small
                          icon={<IconPlus />}
                          onClick={addNewTemplate}
                        />
                      </div>
                    )}
                  </div>
                )}
              </React.Fragment>
            )}
          </div>
        </Modal>
      );
    }
  )
);
