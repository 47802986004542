export const fileType = {
  CONTRACT: "CONTRACT",
  CONTRACTDRAFT: "CONTRACT",
  DOCUMENTS: "DOCUMENT",
  PLANS: "PLAN",
  LINKS: "LINK",
};

export const docType = {
  CONTRACT: "contracts",
  CONTRACTDRAFT: "contracts",
  DOCUMENTS: "documents",
  PLANS: "plans",
  LINKS: "links",
};

export const commonFileExtensions = [
  "PNG",
  "ZIP",
  "JPG",
  "PDF",
  "XLS",
  "XLSX",
  "DOC",
  "DOCX",
];
