import React, {useState, useMemo, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
// local
import {Modal} from "components/Modal";
import {Preloader} from "components/Preloader";
import {
  IconStandPhone,
  IconMailLetter,
  IconLicense,
  IconTags,
  IconInbox,
  IconBriefcase,
} from "components/icons/index";
import {InitialsBadge} from "components/InitialsBadge";
import {getFullName} from "helpers/helper";
import {Tag} from "components/Tag";
import {ProjectLabel} from "components/ProjectLabel";
import EmptyData from "components/EmptyData";
import {InfoTooltip} from "components/InfoTooltip";
import {REQUESTS} from "constants/routes.const";
import {color} from "constants/color.consts";

export const ModalContactMember = ({
  contactId,
  getData,
  onClose,
  organizationName,
  isActive,
  currentUser,
  addClass,
}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [contact, setContact] = useState(null);
  const user = useMemo(() => contact?.User, [contact]);
  const userFullName = useMemo(
    () => getFullName(user?.firstName, user?.lastName),
    [user]
  );
  const regions = useMemo(() => contact?.availableIn, [contact]);
  const regionsString = useMemo(
    () =>
      regions?.length > 2
        ? ` (${regions
            .reduce((str, region) => str.concat(t(region), ", "), "")
            .slice(0, -2)})`
        : "",
    [regions, t]
  );
  const regionTooltip = useMemo(
    () =>
      regions?.length
        ? `${t("CONTACT_MEMBER_MODAL_HAVE_REGIONS_TOOLTIP").format(
            userFullName,
            organizationName
          )}${regionsString}`
        : "CONTACT_MEMBER_MODAL_NO_REGIONS_TOOLTIP",
    [regions, t, organizationName, regionsString, userFullName]
  );

  const RegionTag = (props) => <Tag color={color.green} bold miniSelectView {...props} />;

  const regionsTags = (
    <React.Fragment>
      {regions?.length ? (
        <React.Fragment>
          <RegionTag text={t(regions[0])} addClass="fz-12 mr-1" />
          {regions?.length > 1 && (
            <RegionTag text={t(regions[1])} addClass="fz-12 mr-1" />
          )}
        </React.Fragment>
      ) : (
        <RegionTag
          text={t("CONTACT_MEMBER_MODAL_NO_REGION")}
          addClass="fz-12 mr-1 __italic"
          noBackground
        />
      )}
      {regions?.length > 2 && <span className="mr-1">{`+${regions?.length - 2}`}</span>}
      <InfoTooltip tooltip={regionTooltip} centerText />
    </React.Fragment>
  );

  useEffect(() => {
    if (contactId && !contact) {
      setLoading(true);
      getData(contactId)
        .then(setContact)
        .finally(() => setLoading(false));
    }
  }, [contactId, contact, getData]);

  return (
    <Modal
      width={800}
      rounded
      noTitle
      tightTitle
      whiteTitle
      noCenterContent
      onClose={() => {
        setContact(null);
        onClose();
      }}
    >
      {loading ? (
        <Preloader addClass="preloader500" />
      ) : (
        <div className="contact-member-modal__content">
          <div className="contact-member-modal__contact-info">
            <InitialsBadge firstName={user?.firstName} lastName={user?.lastName} huge />
            <div>
              <div className="contact-member-modal__contact-info__item fz-20 __bold">
                {userFullName}
              </div>
              <div className="contact-member-modal__contact-info__item email">
                <IconMailLetter />
                <span>{contact.email ?? "--"}</span>
              </div>
              <div className="contact-member-modal__contact-info__item">
                <IconStandPhone />
                <span>{contact.phone ?? contact.mobilePhone ?? "--"}</span>
              </div>
            </div>
            <div>
              <div className="contact-member-modal__contact-info__item">
                <IconLicense />
                <span>{contact.position || "--"}</span>
              </div>
              <div className="contact-member-modal__contact-info__item">
                <IconTags />
                <span>{contact.workKinds || "--"}</span>
              </div>
              <div className="contact-member-modal__contact-info__item">
                <IconInbox />
                {regionsTags}
              </div>
            </div>
          </div>
          {!isActive && (
            <div className="contact-member-modal__rfps-title">
              <IconBriefcase />
              {t("CONTACT_MEMBER_MODAL_RFPS_TITLE")}
            </div>
          )}
          {!isActive && (
            <div className="contact-member-modal__rfps-list">
              {!!contact?.rfts?.length ? (
                contact?.rfts?.map((rfp, i) => (
                  <div
                    key={i}
                    className="contact-member-modal__rfps-item"
                    onClick={() => navigate(`${REQUESTS}/${rfp.id}`)}
                  >
                    <div className="contact-member-modal__rfps-item__name">
                      {rfp.name}
                    </div>
                    <ProjectLabel
                      name={rfp.projectName}
                      code={`${t(rfp.projectAvailableIn?.[0])}${
                        rfp.projectAvailableIn?.length > 1
                          ? ` +${rfp.projectAvailableIn?.length - 1}`
                          : ""
                      }`}
                      color="green"
                      codeRounded
                      mediumText
                      addClass="ml-2 __nonecase __normal"
                    />
                  </div>
                ))
              ) : (
                <EmptyData addClass="pb-4" />
              )}
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};
