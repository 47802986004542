import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import cn from "classnames";
// local
import {CustomTooltip} from "components/CustomTooltip";
import {InitialsBadge} from "components/InitialsBadge";
import {IconBookmark, IconBookmarkFilled} from "components/icons";
import {getFullName} from "helpers/helper";

export const ButtonBookmarkByMembers = ({
  bookmarks,
  currentUser,
  tooltipEmpty,
  tooltipFilled,
  showStatsOnly,
  onClick,
  addClass,
}) => {
  const {t} = useTranslation();

  const members = useMemo(
    () =>
      bookmarks
        ?.filter((item) => !!item.OrganizationMember)
        .map((item) => item.OrganizationMember),
    [bookmarks]
  );

  const isBookmarked = useMemo(() => !!members?.length, [members]);
  const isBookmarkedByMe = useMemo(
    () => isBookmarked && !!members.find((member) => member.User?.id === currentUser.id),
    [isBookmarked, members, currentUser]
  );
  const bookmarkedMember = isBookmarked
    ? members.find((member) => member.User?.id === currentUser.id) ?? members[0]
    : null;
  const isCurrentUserBookmarkedMember = bookmarkedMember?.User?.id === currentUser.id;
  const firstListFullName = isCurrentUserBookmarkedMember
    ? t("YOU")
    : getFullName(bookmarkedMember?.User?.firstName, bookmarkedMember?.User?.lastName);
  const fullNames = [
    bookmarkedMember ? firstListFullName : "",
    ...(members
      ?.filter((member) => member.id !== bookmarkedMember?.id)
      .filter((member) => !!member.User)
      ?.map((member) => getFullName(member.User.firstName, member.User.lastName)) ?? []),
  ].join(", ");

  const icon = () =>
    isBookmarkedByMe ? (
      <IconBookmarkFilled addClass="btn-bookmark-members__filled-icon" />
    ) : (
      <IconBookmark addClass="btn-bookmark-members__icon" />
    );

  return (
    <div
      className={cn("btn-bookmark-members", {
        [addClass]: !!addClass,
        "mr-1": showStatsOnly && isBookmarked,
      })}
    >
      {!showStatsOnly && (
        <CustomTooltip text={isBookmarkedByMe ? tooltipFilled : tooltipEmpty} centerText>
          <div className="hght-20" onClick={() => onClick(!isBookmarkedByMe)}>
            {icon()}
          </div>
        </CustomTooltip>
      )}
      {!showStatsOnly && bookmarkedMember && <div className="wdth-5"></div>}
      {bookmarkedMember && (
        <CustomTooltip
          text={t("PUBLIC_RFT_PAGE_BOOKMARK_MEMBERS_TOOLTIP").format(fullNames)}
          centerText
        >
          <div className="btn-bookmark-members__initials">
            <InitialsBadge
              firstName={bookmarkedMember.User?.firstName}
              lastName={bookmarkedMember.User?.lastName}
              isCurrentUser={bookmarkedMember.User.id === currentUser.id}
            />
          </div>
        </CustomTooltip>
      )}
      {bookmarkedMember && members.length > 1 && (
        <span className="fz-10 __bold black">{`+${members.length - 1}`}</span>
      )}
    </div>
  );
};
