import React, {useState} from "react";
import {inject, observer} from "mobx-react";
import {useTranslation} from "react-i18next";
//local
import SellerSideProposalView from "../components/RequestView/SellerSideProposalView";
import SellerSideProposalEdit from "../components/RequestEdit/SellerSideProposalEdit";
import {showSuccess} from "helpers/notifications.helper";

const SellerSideProposal = inject("store")(
  observer(({store: {auth, buyer, seller, clientStore}}) => {
    const {currentOffer, currentRequest} = buyer;
    const {user} = auth;
    const {language} = clientStore;
    const {t} = useTranslation();
    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(false);

    const updateOfferFiles = (OfferFiles) =>
      buyer.updateOffer({...currentOffer, OfferFiles});

    const onSubmit = (values) => {
      setLoading(true);
      const documents = currentOffer.OfferFiles?.filter((item) => !!item.File).map(
        (item) => item.File.id
      );
      buyer
        .saveOffer({...values, documents})
        .then(() => {
          showSuccess(t("OFFER_UPDATED"));
          setEditMode(false);
        })
        .finally(() => setLoading(false));
    };

    const handleAddFiles = (files) =>
      updateOfferFiles([...currentOffer?.OfferFiles, ...files]);

    const handleRemoveFile = (id) =>
      updateOfferFiles(
        id ? currentOffer?.OfferFiles.filter((file) => file.id !== id) : []
      );

    return (
      <div className="proposal-card seller-side">
        {editMode ? (
          <SellerSideProposalEdit
            offer={currentOffer}
            request={currentRequest}
            loading={loading}
            onSave={onSubmit}
            onCancel={() => setEditMode(false)}
            onUploadFile={seller.uploadSellerFile}
            onAddFile={handleAddFiles}
            onRemoveFile={handleRemoveFile}
          />
        ) : (
          <SellerSideProposalView
            offer={currentOffer}
            request={currentRequest}
            language={language}
            currentUser={user}
            onEdit={() => setEditMode(true)}
          />
        )}
      </div>
    );
  })
);

export default SellerSideProposal;
