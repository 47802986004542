import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
import {Form, Formik} from "formik";
// local
import {Modal} from "components/Modal";
import {showSuccess} from "helpers/notifications.helper";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import LoadDocsColumn from "components/LoadDocsColumn";
import EditDatePicker from "components/EditDatePicker";
import FormikField from "components/FormikField";
import {FieldInput} from "components/Field";
import {schema} from "pages/OurRequests/components/manualProposalSchema";
import {ConfirmModal} from "components/ConfirmModal";
import {IconPushPin, IconTrashCan, IconDocumentPen} from "components/icons";
import {emptyManualOffer} from "constants/buyer.consts";
import {uploadFiles, removeFilesConfirmModal} from "helpers/helper";
import {commonFileExtensions} from "constants/file.consts";
import {Preloader} from "components/Preloader";
import {color} from "constants/color.consts";

export const ModalManualOffer = inject("store")(
  observer(({store: {buyer}, requestId, offerId, onClose}) => {
    const [offer, setOffer] = useState(null);
    const [offerDocuments, setOfferDocuments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [uploadLoading, setUploadLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const {t} = useTranslation();

    const isNewOffer = offerId === "new-offer";

    const handleDeleteOffer = () =>
      ConfirmModal({
        title: t("WANT_DELETE_MANUAL_OFFER"),
        text: t("CANT_DELETE_MANUAL_OFFER"),
        type: "warning",
        onOk: () => onRemove(requestId, offerId),
      });

    const onRemove = (requestId, offerId) =>
      buyer.deleteManualOffer(requestId, offerId).then(() => {
        showSuccess(t("SUCCESSFULLY_REMOVED"));
        onClose(true);
      });

    const clickAdd = (files) =>
      uploadFiles(
        files,
        buyer.getManualOfferFile,
        (files) => setOfferDocuments([...files, ...offerDocuments]),
        setUploadLoading
      );

    const clickRemove = (id) => removeFilesConfirmModal(id, removeDocument);

    const removeDocument = (id) => {
      setOfferDocuments(
        id ? offerDocuments.filter((document) => document.id !== id) : []
      );
      showSuccess(t(id ? "FILE_DELETED" : "ALL_FILES_DELETED"));
    };

    const onSubmit = async (values) => {
      setLoadingSave(true);
      try {
        if (isNewOffer)
          await buyer.createManualOffer(requestId, {
            ...values,
            companyNumber: values.companyNumber?.replace("-", ""),
            documents: offerDocuments.map((doc) => doc.File.id),
          });
        else
          await buyer.updateManualOffer(requestId, offerId, {
            ...values,
            companyNumber: values.companyNumber?.replace("-", ""),
            documents: offerDocuments.map((doc) => doc.File.id),
          });
        onClose(true);
        showSuccess(t(`SUCCESSFULLY_${isNewOffer ? "CREATED" : "UPDATED"}`));
      } finally {
        setLoadingSave(false);
      }
    };

    useEffect(() => {
      if (isNewOffer) {
        setOffer(emptyManualOffer);
        setLoading(false);
      } else
        buyer
          .getManualOffer(requestId, offerId)
          .then((data) => {
            setOffer(data);
            setOfferDocuments(data?.ManualOfferFiles ?? []);
          })
          .finally(() => setLoading(false));
      return () => setOffer(null);
    }, [buyer, requestId, offerId, isNewOffer]);

    return (
      <Modal
        titleIcon={<IconPushPin color={color.black} addClass="mr-2" />}
        titleText="MODAL_MANUAL_OFFER_TITLE"
        boldTitle
        tightTitle
        width={550}
        percentageWide="35%"
        noCenterContent
        rounded
        style={{marginTop: "5vh"}}
        onClose={onClose}
      >
        <div className="">
          {loading && <Preloader addClass="preloader250" />}
          {!loading && (
            <Formik
              enableReinitialize
              initialValues={{
                ...offer,
                companyNumber: null,
              }}
              validationSchema={schema}
              onSubmit={onSubmit}
            >
              {({values, isValid, dirty, setFieldValue}) => (
                <Form className="modal-manual-offer__content">
                  <div className="df-row">
                    <FormikField
                      name="organizationName"
                      placeholder={t("COMPANY_NAME_PLACEHOLDER")}
                      label={t("MODAL_MANUAL_OFFER_NAME_LABEL")}
                      small
                      autoResize={{minWidth: "180px", maxWidth: "90%"}}
                      errorName="organizationName"
                      className="input"
                    />

                    <FieldInput
                      name="companyNumber"
                      mask="999999-9999"
                      maskChar="x"
                      placeholder="xxxxxx-xxxx"
                      label="MODAL_MANUAL_OFFER_NUMBER_LABEL"
                      small
                      errorName="companyNumber"
                      defaultValue={offer.companyNumber}
                      addWrapperClass="ml-8"
                      onChange={(e) => setFieldValue("companyNumber", e.target.value)}
                    />

                    {offerId !== "new-offer" && (
                      <ButtonTransparent
                        icon={<IconTrashCan />}
                        noBorder
                        onClick={handleDeleteOffer}
                        addClass="modal-manual-offer__content__delete-button"
                      />
                    )}
                  </div>

                  <div className="df">
                    <FormikField
                      name="price"
                      label={t("MODAL_MANUAL_OFFER_PRICE_LABEL")}
                      placeholder={t("PRICE_PLACEHOLDER")}
                      small
                      type="number"
                      errorName="price"
                      className="input"
                    />
                    <EditDatePicker
                      label={t("MODAL_MANUAL_OFFER_DATE_LABEL")}
                      addClass="ml-8"
                      name="validUntil"
                      small
                      placeholder="SELECT_DATE"
                    />
                  </div>

                  <LoadDocsColumn
                    title={"OFFER_DOCUMENT"}
                    docPlaceholder={"PLACEHOLDER_DOCUMENT_COLUMN_EXTENDED"}
                    titleIcon={<IconDocumentPen />}
                    button={"ADD_DOCUMENT"}
                    editable
                    docs={offerDocuments}
                    fullWidth
                    addFile={clickAdd}
                    removeFile={clickRemove}
                    canRemoveAllFiles
                    maxFileRows={5}
                    customFileTypes={[...commonFileExtensions, "MSG", "EML"]}
                    loadColumnTitle={"OFFER_DOCUMENT"}
                    loading={uploadLoading}
                    addClass="mt-1 mb-3"
                  />

                  <FormikField
                    name="buyerNote"
                    label={t("OUR_NOTES")}
                    placeholder={t("MODAL_MANUAL_OFFER_NOTES_PLACEHOLDER")}
                    autoSize={{maxRows: 4}}
                    maxLength="1000"
                    addClass="modal-manual-offer__content__notes"
                    textarea
                    textareaValue={values.buyerNote}
                  />

                  <div className="df-row-jce-center mt-2">
                    <ButtonTransparent
                      name={"CANCEL"}
                      color="red"
                      medium
                      disabled={uploadLoading}
                      onClick={onClose}
                      addClass="mr-4"
                    />
                    <ButtonTransparent
                      type="submit"
                      loading={loadingSave}
                      medium
                      disabled={(!dirty && isNewOffer) || !isValid || uploadLoading}
                      name={"SAVE"}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </Modal>
    );
  })
);
