import React, {useState, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
//local
import {Modal} from "components/Modal";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {InfoTooltip} from "components/InfoTooltip";
import {ConfirmModal} from "components/ConfirmModal";
import QualificationAnswer from "./components/QualificationAnswer";
import ContactPerson from "components/ContactPerson";
import {qualificationStatus} from "constants/user.consts";
import {IconList} from "components/icons";

export const QualificationAnswersModal = inject("store")(
  observer(
    ({
      store: {clientStore},
      initialQualification,
      isAdminOwner,
      buyerSide,
      readOnly,
      removeQualificationAnswers,
      confirmQualificationAnswers,
      onClose,
    }) => {
      const {t} = useTranslation();
      const [qualification, setQualification] = useState(initialQualification);
      const [saveLoading, setSaveLoading] = useState(false);
      const [sendLoading, setSendLoading] = useState(false);
      const [fileLoading, setFileLoading] = useState(false);

      const disableSaveSendButton = useMemo(
        () => !qualification.answers?.length || fileLoading || !isAdminOwner,
        [fileLoading, qualification.answers, isAdminOwner]
      );
      const disableSaveButton = disableSaveSendButton || sendLoading;
      const disableSendbutton = useMemo(
        () =>
          disableSaveSendButton ||
          saveLoading ||
          qualification.status !== qualificationStatus.WAITING ||
          qualification.questions.length !== qualification.answers?.length ||
          !qualification.answers?.every((answer) => answer.yes !== null),
        [
          disableSaveSendButton,
          saveLoading,
          qualification.status,
          qualification.questions,
          qualification.answers,
        ]
      );

      const handleChangeAnswer = (values, isNew) =>
        setQualification({
          ...qualification,
          answers: isNew
            ? [...qualification.answers, values]
            : qualification.answers.map((answer) =>
                answer.questionId === values.questionId ? values : answer
              ),
        });

      const handleLoadFile = (file, id, isNew) => {
        setFileLoading(true);
        clientStore
          .qualificationAnswerUploadFile(file)
          .then((data) =>
            setQualification({
              ...qualification,
              answers: isNew
                ? [
                    ...qualification.answers,
                    {
                      questionId: id,
                      yes: null,
                      files: [data],
                    },
                  ]
                : qualification.answers.map((answer) =>
                    answer.questionId === id
                      ? {
                          ...answer,
                          files: [...answer.files, data],
                        }
                      : answer
                  ),
            })
          )
          .finally(() => setFileLoading(false));
      };

      const handleDeleteFile = (fileId, questionId) =>
        setQualification({
          ...qualification,
          answers: qualification.answers.map((answer) =>
            answer.questionId === questionId
              ? {
                  ...answer,
                  files: answer.files.filter((file) => file.id !== fileId),
                }
              : answer
          ),
        });

      const handleSaveSendQuestions = ({needSend}) => {
        if (!isAdminOwner)
          return ConfirmModal({
            title: t(
              needSend
                ? "WANT_SEND_QUALIFICATION_ANSWERS"
                : "WANT_SAVE_QUALIFICATION_ANSWERS"
            ),
            text: t("CANT_SAVE_QUALIFICATION_QUESTIONS"),
            type: "warning",
            noCancelButton: true,
            noQuestionSymbol: true,
            onOk: () => {},
          });
        needSend ? setSendLoading(true) : setSaveLoading(true);
        clientStore
          .saveQualificationAnswers(qualification.id, {
            suppliersQualificationAnswers: qualification.answers.map((answer) => ({
              ...answer,
              files: answer.files.map((file) => file.id),
            })),
          })
          .then(async () => {
            if (needSend) await clientStore.sendQualificationAnswers(qualification.id);
            onClose({needUpdateAnswers: needSend});
          })
          .finally(() => (needSend ? setSendLoading(false) : setSaveLoading(false)));
      };

      return (
        <Modal
          onClose={onClose}
          width={720}
          whiteTitle
          boldTitle
          noCenterContent
          titleIcon={<IconList height="40" width="40" addClass="mr-4" />}
          suffixTitleIcon={
            !readOnly && (
              <InfoTooltip
                tooltip="QUALIFICATION_ANSWERS_MODAL_TITLE_TOOLTIP"
                centerText
                addClass="ml-2"
              />
            )
          }
          titleText={`${t("QUALIFICATION_ANSWERS_MODAL_TITLE")} ${
            qualification[buyerSide ? "Seller" : "Buyer"]?.name
          }`}
          addClass="qualifications-answers-modal__wrapper"
        >
          <div className="pa-8">
            {buyerSide && qualification.SupplierUser && (
              <div className="qualifications-answers-modal__users">
                <div className="qualifications-answers-modal__users_submit">
                  <div className="__uppercase __bold fz-12">
                    {t("QUALIFICATION_ANSWERS_MODAL_SUBMIT")}
                  </div>
                  <ContactPerson
                    key={`${qualification.SupplierUser?.email}-${qualification.SupplierUser?.mobilePhone}`}
                    person={qualification.SupplierUser}
                    actionDate={qualification.updatedAt}
                    needPhoneEmailTooltip
                    hasBackground
                    addClass="mb-2 mt-1"
                  />
                </div>
              </div>
            )}
            {!!qualification.questions?.length && (
              <div className="qualifications-answers-modal__answers">
                {qualification.questions?.map((question, index) => (
                  <QualificationAnswer
                    key={question.id}
                    number={index}
                    question={question}
                    answer={qualification.answers?.find(
                      (answer) => answer.questionId === question.id
                    )}
                    onChange={handleChangeAnswer}
                    onAddFile={handleLoadFile}
                    onDeleteFile={handleDeleteFile}
                    isAdminOwner={isAdminOwner}
                    buyerSide={buyerSide}
                    loading={fileLoading}
                  />
                ))}
              </div>
            )}
            {!readOnly && (
              <div className="df-row-jce-center mt-4">
                {buyerSide ? (
                  <React.Fragment>
                    <ButtonTransparent
                      name="QUALIFICATION_ANSWERS_MODAL_BUTTON_REMOVE"
                      medium
                      disabled={qualification.status === qualificationStatus.REMOVED}
                      color="red"
                      onClick={() => removeQualificationAnswers(qualification.id)}
                    />
                    <ButtonTransparent
                      name={
                        qualification.status === qualificationStatus.CONFIRMED
                          ? "QUALIFICATION_ANSWERS_MODAL_BUTTON_CONFIRMED"
                          : "QUALIFICATION_ANSWERS_MODAL_BUTTON_CONFIRM"
                      }
                      medium
                      color="green"
                      nonTransparent
                      disabled={qualification.status === qualificationStatus.CONFIRMED}
                      addClass="ml-4"
                      onClick={() => confirmQualificationAnswers(qualification.id)}
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <ButtonTransparent
                      name="CANCEL"
                      medium
                      color="red"
                      onClick={onClose}
                    />
                    <ButtonTransparent
                      name="SAVE"
                      medium
                      addClass="mr-8 ml-4"
                      disabled={disableSaveButton}
                      loading={saveLoading}
                      onClick={handleSaveSendQuestions}
                    />
                    <ButtonTransparent
                      name={
                        qualification.status === qualificationStatus.WAITING
                          ? "QUALIFICATION_ANSWERS_MODAL_BUTTON_SEND"
                          : "QUALIFICATION_ANSWERS_MODAL_BUTTON_SENT"
                      }
                      medium
                      nonTransparent
                      disabled={disableSendbutton}
                      loading={sendLoading}
                      onClick={() => handleSaveSendQuestions({needSend: true})}
                    />
                  </React.Fragment>
                )}
              </div>
            )}
          </div>
        </Modal>
      );
    }
  )
);
